import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/auth-context";
import Button from "../components/atoms/Button";
import moment from "moment";
import VenueCard from "../components/organisms/VenueCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import Spinner from "../components/atoms/Spinner";
import { getQueuesAPI } from "../util";
import { v4 as uuidv4 } from "uuid";
import OutageAlert from "../components/atoms/OutageAlert";

export default function Locations() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const [currentOccupancy, setCurrentOccupancy] = useState({ raw: 0, perc: 0 });

  const [showOutageAlert, setShowOutageAlert] = useState(false);

  const handleDismiss = () => setShowOutageAlert(false);

  const fetchData = () => {
    setIsLoading(true);
    setLocations(require("../vendors/braves/locations.json").locations);
    // Main Data Route for Locations Page (main entry into app)
    // Because this is first data route if 401 we segue to auth login page
    getQueuesAPI(
      "get-clubhouse-current-data-dashboard",
      {},
      (data, error) => {

        if (error) {
          // Sometimes user is logged out/token expired so we get 401
          // Not logged in / invalid token - unauthorized
          if (error.status === 401) {
            authCtx.logout();
            navigate("/auth");
          } else {
            // Handle errors that arent 401
            alert("Failed to fetch data. Please refresh the page and try again.");
          }
        } else if (data) {

          setShowOutageAlert(data.filter(sensor => sensor.sensorActive).length / data.length <= 0.5);

          let occ = data.reduce((x, y) => x + y.occupancy, 0);
          let perc = Math.round((occ / 191) * 100);
          setCurrentOccupancy({ raw: occ, perc: perc });
        } else {
          alert("Received invalid data from the server.");
        }
        setIsLoading(false);
      },
      authCtx.token
    );
  };

  const refreshLocations = () => {
    fetchData();
  };

  useEffect(() => {
    fetchData();
    if (
      authCtx.loggedIn &&
      moment.unix(authCtx.tokenExpirationTime) < moment(new Date())
    ) {
      // Sometimes if the webpage is left open and user comes back - this might not execute
      // So we navigate to the Auth page in the data call above as well as here
      authCtx.logout();
      navigate("/auth");
    }
  }, [authCtx, navigate]);

  // Uncomment for storing id in local storage
  // useEffect(() => {
  //   locations.forEach((location) => {
  //     localStorage.setItem(`locationId-${location.name.replace(/ /g, '-')}`, location.id);
  //   });
  // }, [locations]);

  return (
    <div className="relative min-h-screen overflow-y-auto">
      <div className="p-4">
        {authCtx.loggedIn &&
          moment.unix(authCtx.tokenExpirationTime) > moment(new Date()) ? (
          <>
            <div className="between mb-4">
              <Button
                className="border-0"
                onClick={refreshLocations}
                disabled={isLoading}>
                {isLoading ? (
                  <Spinner size={20} />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faArrowRotateRight} />
                    <span style={{ marginLeft: "8px" }}>Refresh</span>
                  </>
                )}
              </Button>
            </div>
            <div className="row">
              {locations.map((x) => (
                <VenueCard key={uuidv4()} item={x} occupancy={currentOccupancy} />
              ))}
            </div>
          </>
        ) : (
          <>
            {authCtx.loggedIn &&
              moment.unix(authCtx.tokenExpirationTime) < moment(new Date()) ? (
              <Link to={"/auth"}>
                Session Token expired or invalid. Please log out, then log back
                in for a new session token
              </Link>
            ) : (
              <Link to={"/auth"}>
                Not Authorized. Please Log-In with given credentials or contact
                support.
              </Link>
            )}
          </>
        )}
      </div>
      <OutageAlert show={showOutageAlert} onDismiss={handleDismiss} />
    </div>
  );
}
