import React, { useCallback, useState, useRef, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import 'chartjs-adapter-moment';

const OccupancyChart = ({ data, dayType }) => {
  const [dataValues, setDataValues] = useState([]);
  const [chartResolution, setChartResolution] = useState(1);
  const [loading, setLoading] = useState(true);
  const resolutions = [1, 5, 10, 15, 30, 60];
  const scrollContainerRef = useRef(null);

  const getIntervalKey = (incrementingValue, resolution) => {
    const baseDate = new Date(data[0].time2);
    baseDate.setHours(0, 0, 0, 0);
    const newDate = new Date(baseDate.getTime() + incrementingValue * 60000);
    newDate.setMinutes(Math.floor(newDate.getMinutes() / resolution) * resolution, 0, 0);
    return newDate.toISOString();
  };

  const increaseWidth = () => {
    const currentIndex = resolutions.indexOf(chartResolution);
    if (currentIndex < resolutions.length - 1) {
      setChartResolution(resolutions[currentIndex + 1]);
      updateDataMap(resolutions[currentIndex + 1]);
    }
  };

  const decreaseWidth = () => {
    const currentIndex = resolutions.indexOf(chartResolution);
    if (currentIndex > 0) {
      setChartResolution(resolutions[currentIndex - 1]);
      updateDataMap(resolutions[currentIndex - 1]);
    }
  };

  const resetWidth = () => {
    setChartResolution(resolutions[0]);
    updateDataMap(resolutions[0]);
  };

  const updateDataMap = useCallback((resolution = chartResolution) => {
    const dataMap = new Map();

    data.forEach((item) => {
      if (item.time > 600) {
        const key = getIntervalKey(item.time, resolution);
        const entry = dataMap.get(key) || { totalPerc: 0, count: 0 };

        entry.totalPerc += item.perc;
        entry.count++;

        dataMap.set(key, { ...entry, dateTime: key });
      }
    });

    const aggregatedData = Array.from(dataMap.values()).map(
      ({ dateTime, totalPerc, count }) => ({
        dateTime,
        perc: Math.round(totalPerc / count),
      })
    );

    setDataValues(aggregatedData);
    setLoading(false); // Set loading to false when data is fully loaded
  }, [data, chartResolution]);

  const maxValue = Math.max(...dataValues.map((item) => item.perc));

  // Gets primary text color for chart
  const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-txt').trim();

  const chartData = {
    labels: dataValues.map((item) => item.dateTime),
    datasets: [
      {
        label: `Avg. Occupancy`,
        data: dataValues.map((item) => item.perc),
        backgroundColor: dataValues.map((item) =>
          item.perc >= 100 ? "red" : "#53b8aa"
        ),
        borderRadius: 2.5,
        barThickness: chartResolution,
      },
    ],
  };

  const mainChartOptions = {
    scales: {
      x: {
        type: "time",
        title: {
          display: true,
          text: "Time",
          color: primaryTextColor,
        },
        time: {
          unit: "hour",
          displayFormats: {
            hour: "h A",
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          source: "auto",
          color: primaryTextColor,
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        display: false, // Disable y-axis for main chart
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (percent) => {
            return `${percent.raw}% | ${Math.round(percent.raw * 191 / 100)} of 191`
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  const yAxisChartOptions = {
    scales: {
      x: {
        display: false, // Disable x-axis for y-axis chart
      },
      y: {
        display: true,
        position: 'left',
        title: {
          color: primaryTextColor,
          display: true,
          text: "Percentage",
        },
        grid: {
          display: false,
        },
        ticks: {
          color: primaryTextColor,
          precision: 0,
          beginAtZero: true,
          stepSize: 20,
          callback: function (value) {
            if (value % 20 === 0) {
              return value;
            }
          },
        },
        max: Math.max(Math.ceil(maxValue / 20) * 20),
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: false,
      },
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setLoading(true); // Set loading true when data fetch starts
    updateDataMap();

    const scrollElement = scrollContainerRef.current;
    if (scrollElement) {
      const maxScrollLeft = scrollElement.scrollWidth - scrollElement.clientWidth;
      let start = null;

      const step = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const d = Math.min(progress / 2000, 1);
        scrollElement.scrollLeft = maxScrollLeft * d;

        if (progress < 2000) {
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);
    }
  }, [updateDataMap]);

  if (!loading) {
    return (
      <div 
      className="panel-2"
      style={{
        paddingRight: "24px",
        paddingLeft: "24px",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderRadius: "36px",
        marginTop: "16px",
        marginBottom: "16px",
        justifyContent: "center",
        // backgroundColor: "#fafefe",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "16px",
          marginRight: "16px"
        }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ fontSize: "24px" }}>
              Average Total Occupancy %
            </p>
          </div>
          <div>
            <span style={{ fontSize: "16px", margin: "0 20px" }}>
              Bar Scale: {chartResolution} mins.
            </span>
            <button
              style={{ marginRight: "5px" }}
              className={"btn border-1"}
              onClick={increaseWidth}
              disabled={resolutions.indexOf(chartResolution) >= 5}
            >
              +
            </button>
            <button
              style={{ marginRight: "5px" }}
              className={"btn border-1"}
              onClick={decreaseWidth}
              disabled={resolutions.indexOf(chartResolution) <= 0}
            >
              -
            </button>
            <button
              className={"btn border-1"}
              onClick={resetWidth}
              disabled={chartResolution === 5}
            >
              Reset
            </button>
          </div>
        </div>
  
        <div style={{ 
          position: 'relative',
          height: '300px',
          marginTop: '8px',
        }}>
          <div
            ref={scrollContainerRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              marginLeft: '50px',
              overflowX: 'auto',
            }}
          >
            <div style={{
              width: `100%`,
              height: '100%'
            }}>
              <Bar data={chartData} options={mainChartOptions} />
            </div>
          </div>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100px',
            height: '258px',
            pointerEvents: 'none',
          }}>
            <Bar
              data={{...chartData, datasets: []}}
              options={yAxisChartOptions}
            />
          </div>
        </div>
      </div>
    );
  };
}

export default OccupancyChart;