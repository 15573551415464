import { Link } from "react-router-dom";
import {React, useContext} from 'react';
// import logo from "./../../assets/braves_clubhouse_store_logo.png";
import { ThemeContext } from '../../components/molecules/Nav';

export default function VenueCard({ item, occupancy, className = "" }) {

  const [theme, setTheme] = useContext(ThemeContext);

  return (
    <div className="fw-bold mb-4" style={{width: '100%', maxWidth: '400px', minWidth: '300px'}}>
      <Link
        to={`../locations/${item.name.replace(/ /g, '-').toLowerCase()}`}
        state = {{id: item.id}}
        className="p-3 shadow-lg d-block panel-2"
        style={{
          // backgroundColor: "white",
          borderRadius: "20px",
          textDecoration: "none",
          color: "inherit",
        }}
      >
        <div className="d-flex align-items-center">
          <div
            className="rounded-circle me-3 d-flex align-items-center justify-content-center"
            style={{ width: 60, height: 60 }}
          >
            <img
              src={require(theme === "light"
              ? "../../assets/braves_clubhouse_store_logo.png"
              : "../../assets/braves_clubhouse_store_logo_dark.png")}
              alt="Braves Clubhouse Store Logo"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>

          <div className="h3">{item.name}</div>
        </div>
        <hr />
        <div className="text-center">
          <div>Current Occupancy:</div>
          <div
            style={{ fontSize: "4.8rem" }}
            className={occupancy.perc > 99 ? " text-warning" : ""}
          >
            <div>{occupancy.raw}/191</div>
          </div>
          <div
            style={{ fontSize: "2.4rem" }}
            className={occupancy.perc > 99 ? " text-warning" : ""}
          >
            <div>({occupancy.perc}%)</div>
          </div>
        </div>
      </Link>
    </div>
  );
}
