import moment from "moment-timezone";

export const getQueuesAPI = (url, body, callback, token) => {
  fetch("https://api.queuesapp.com/" + url, {
    headers: { "Content-Type": "application/json", token: token },
    method: "POST",
    body: JSON.stringify(body),
  })
    .then((response) => {
      if (!response.ok && url !== "send-dashboard-password-reset-code") {
        //throw new Error(`HTTP error! status: ${response.status}`);
        throw response;
      }
      return response.json();
    })
    .then((data) => callback(data))
    .catch((error) => {
      // Do not have this error line active in PROD! Shows sensitive information
      // console.error("There was a problem with the fetch operation:", error);
      callback(null, error);
    });
};

export const getChanges = (lastLoggedIn) => {
  let allChanges = require("./changes.json");
  return allChanges;
};

export const getData = (id) => {
  return require("./vendors/braves/locations.json").locations.filter(
    (x) => x.id === id
  )[0];
};

export const formatTimestamp = (timestamp) => {
  let year = timestamp.getFullYear();
  let month = ("0" + (timestamp.getMonth() + 1)).slice(-2);
  let day = ("0" + timestamp.getDate()).slice(-2);
  let hours = ("0" + timestamp.getHours()).slice(-2);
  let minutes = ("0" + timestamp.getMinutes()).slice(-2);

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const generateDay = (timestamp) => {
  let day = [];
  for (let x = 0; x < 1440; x++) {
    day.push(
      formatTimestamp(
        moment(timestamp).startOf("day").add(x, "minutes").toDate()
      )
    );
  }
  return day;
};

export const getTeam = (teamId) => {
  let result = require("./vendors/mlb_teams.json").filter(
    (x) => x.tid === teamId
  )[0];
  return result;
};

export const customMoment = (date) => {
  let estOffset = Math.abs(moment(date).tz("America/New_York").utcOffset());
  let localOffset = Math.abs(moment(date).tz("America/New_York").utcOffset());
  return moment(date).subtract(Math.abs(estOffset - localOffset), "minutes");
};

export const getTZOffset = () => {
  let estOffset = Math.abs(moment().tz("America/New_York").utcOffset());
  let localOffset = Math.abs(moment().tz(moment.tz.guess()).utcOffset());
  // console.log(Math.abs(estOffset - localOffset));
  return Math.abs(estOffset - localOffset);
};
