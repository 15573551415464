import React, { useState, useEffect } from "react";
import { Pie } from "react-chartjs-2";
import "chart.js/auto";

const colors = [
  '#0000FF', '#FF0000', '#008000', '#FFA500',
];

const getColorForIndex = (index) => {
  return index < colors.length ? colors[index] : "#000000";
};

const OverviewTrafficPieChart = ({ trafficDataSets, dataSetNames, dataSetDates }) => {
  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);

  // console.log("RENDERING: ")
  // console.log("traffic data sets: ", trafficDataSets)
  // console.log("data set names: ", dataSetNames)
  // console.log("data set dates: ", dataSetDates)
  // console.log("________________________________")

  useEffect(() => {
    if (
      trafficDataSets.length === dataSetNames.length &&
      trafficDataSets.length === dataSetDates.length
    ) {
      const processedTrafficData = trafficDataSets.map((dataSet) => {
        let totalCount = 0;
        let lastCountBeforeClear = 0;
        dataSet.forEach((item, index) => {
          if (item.clear) {
            totalCount += lastCountBeforeClear;
            lastCountBeforeClear = 0;
          } else {
            lastCountBeforeClear = item.count;
          }
          if (index === dataSet.length - 1 && !item.clear) {
            totalCount += lastCountBeforeClear;
          }
        });
        return totalCount;
      });

      const newChartData = {
        labels: dataSetNames.map((name, index) => `${name} · ${dataSetDates[index]}`),
        datasets: [
          {
            label: "Traffic Count",
            data: processedTrafficData,
            backgroundColor: processedTrafficData.map((_, index) => getColorForIndex(index)),
            hoverOffset: 4,
          },
        ],
      };
      // Gets primary text color for chart
      const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-txt').trim();
      
      const newChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          arc: {
            borderWidth: 0,
          },
        },
        plugins: {
          legend: {
            position: 'bottom', 
            align: 'start',  
            labels: {
              color: primaryTextColor,
              usePointStyle: true,
              pointStyle: 'rectRounded',
            },
          },
          tooltip: {
            callbacks: {
              title: (context) => context[0].label,
              label: (context) => ` ${context.raw}`,
            },
          },
        },
      };

      setChartData(newChartData);
      setChartOptions(newChartOptions);
    }
  }, [trafficDataSets, dataSetNames, dataSetDates]);

  return (
    <div
      className="elevated-card-2"
      style={{
        padding: "16px",
        borderRadius: "36px",
        justifyContent: "center",
        // backgroundColor: "#dce5e3",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center", 
        }}
      >
        <p style={{ fontSize: "28px" }}>Traffic Distribution</p>
      </div>

      <div
        style={{
          position: "relative",
          height: "300px",
        }}
      >
        {chartData && chartOptions && (
          <Pie data={chartData} options={chartOptions} />
        )}
      </div>
    </div>
  );
};

export default OverviewTrafficPieChart;