import React, {
    useState,
    useContext,
    useCallback,
    useRef,
    useMemo,
    Fragment,
} from "react";
import moment from "moment";
import AuthContext from "../contexts/auth-context";
import ButtonGroup from "../components/molecules/ButtonGroup";
import { useNavigate } from "react-router-dom";
import {
    formatTimestamp,
    generateDay,
    getQueuesAPI,
    getTeam,
} from "../util";
import OverviewOccupancyChart from "../components/atoms/OverviewOccupancyChart";
import OverviewRawChart from "../components/atoms/OverviewRawChart.js";
import OverviewTrafficChart from "../components/atoms/OverviewTrafficChart.js";
import OverviewTrafficPieChart from "../components/atoms/OverviewTrafficPieChart.js";

import { LinearProgress } from "@mui/material";

const overviewColors = ["#0000FF", "#FF0000", "#008000", "#FFA500"];

export default function Overview({
    currentLocation,
    currentTime,
    sort,
    games,
}) {
    const navigate = useNavigate();
    const authCtx = useContext(AuthContext);

    const [overviewState, setOverviewState] = useState({
        selectedDates: [],
        dataList: [],
        trafficDataList: [],
        gameTitlesList: [],
        loading: false,
    });

    const dataCache = useRef({});

    const getCachedData = (dateToFetch) => {
        const cachedDataString = localStorage.getItem(`overviewData-${dateToFetch}`);
        if (cachedDataString) {
            try {
                return JSON.parse(cachedDataString);
            } catch (error) {
                console.error("Error parsing cached data from localStorage", error);
                return null;
            }
        }
        return null;
    };

    const saveCachedData = (dateToFetch, data) => {
        try {
            localStorage.setItem(`overviewData-${dateToFetch}`, JSON.stringify(data));
        } catch (error) {
            console.error("Error saving data to localStorage", error);
        }
    };

    const handleOverviewGameClick = (gameDate, game) => {
        const gameIndex = game.dates.findIndex((g) => g.date === gameDate.date);
        const teamNameAndGame =
            gameIndex !== -1 ? `${game.teamName} · Game ${gameIndex + 1}` : "Game not found";

        const dateExists = overviewState.selectedDates.includes(gameDate.date);

        if (dateExists) {
            const indexToRemove = overviewState.selectedDates.indexOf(gameDate.date);
            setOverviewState((prevState) => ({
                selectedDates: prevState.selectedDates.filter((_, index) => index !== indexToRemove),
                dataList: prevState.dataList.filter((_, index) => index !== indexToRemove),
                trafficDataList: prevState.trafficDataList.filter((_, index) => index !== indexToRemove),
                gameTitlesList: prevState.gameTitlesList.filter((title) => title !== teamNameAndGame),
            }));
        } else {
            if (overviewState.selectedDates.length >= 4) {
                alert("Maximum amount of games is 4.");
                return;
            }

            setOverviewState((prevState) => ({
                selectedDates: [...prevState.selectedDates, gameDate.date],
                dataList: prevState.dataList,
                trafficDataList: prevState.trafficDataList,
                gameTitlesList: [...prevState.gameTitlesList, teamNameAndGame],
            }));

            fetchDataForGraph(gameDate.date);
        }
    };

    const fetchDataForGraph = (dateToFetch) => {
        if (dataCache.current[dateToFetch]) {
            const cachedData = dataCache.current[dateToFetch];
            setOverviewState((prevState) => ({
                ...prevState,
                dataList: [...prevState.dataList, cachedData.processedData],
                trafficDataList: [...prevState.trafficDataList, cachedData.trafficData],
            }));
            return;
        }

        const localStorageData = getCachedData(dateToFetch);
        if (localStorageData) {
            dataCache.current[dateToFetch] = localStorageData;
            setOverviewState((prevState) => ({
                ...prevState,
                dataList: [...prevState.dataList, localStorageData.processedData],
                trafficDataList: [...prevState.trafficDataList, localStorageData.trafficData],
            }));
            return;
        }

        setOverviewState((prevState) => ({ ...prevState, loading: true }));

        const occupancyDataPromise = new Promise((resolve, reject) => {
            getQueuesAPI(
                "get-clubhouse-daily-data",
                { locationId: 1, date: moment(dateToFetch).format("yyyy-MM-DD") },
                (data_, error) => {
                    if (error) {
                        if (error.status === 200) {
                            authCtx.logout();
                            navigate("/auth");
                        } else {
                            alert("Failed to fetch data. Please refresh the page and try again.");
                        }
                        reject(error);
                    } else {
                        try {
                            const zones_ = currentLocation.zones.map((currentZone, index) => {
                                let currentData = [];
                                if (data_ && data_[index] && data_[index][currentZone.id.toString()]) {
                                    currentData = data_[index][currentZone.id.toString()];
                                } else {
                                    console.warn(`No data found for zone ${currentZone.id} at index ${index}`);
                                }

                                const formattedData = currentData.map((item, timeIndex) => ({
                                    zone: currentZone.id,
                                    time: timeIndex,
                                    time2: item?.time || "",
                                    traffic: item?.occupancy || 0,
                                }));

                                const peak = formattedData.reduce(
                                    (max, item) => (item.traffic > max.traffic ? item : max),
                                    { traffic: -Infinity }
                                );

                                return {
                                    zoneId: currentZone.id,
                                    zoneData: formattedData,
                                    label: currentZone.label,
                                    x: currentZone.x,
                                    y: currentZone.y,
                                    redDotX: currentZone.circleX,
                                    redDotY: currentZone.circleY,
                                    peak,
                                };
                            });

                            const day = generateDay(dateToFetch);
                            const zoneDataMap = new Map(
                                zones_.map((zone) => [zone.zoneId, zone.zoneData])
                            );

                            const processed = day.reduce((acc, currentMinute, x) => {
                                const time = new Date(currentMinute);
                                const formattedTime = formatTimestamp(time);

                                const minuteData = zones_
                                    .map((zone) => {
                                        const match = zoneDataMap
                                            .get(zone.zoneId)
                                            .find((z) => z.time2 === formattedTime);
                                        return match ? { traffic: match.traffic, id: zone.zoneId } : null;
                                    })
                                    .filter(Boolean);

                                const total = minuteData.reduce((sum, item) => sum + item.traffic, 0);
                                const perc = Math.round((total / 191) * 100);

                                acc.push({
                                    time: x,
                                    time2: currentMinute,
                                    total,
                                    perc,
                                    traffic: minuteData,
                                });

                                return acc;
                            }, []);

                            zones_.sort((x, y) => {
                                switch (sort) {
                                    case "zone-id":
                                        return x.zoneId - y.zoneId;
                                    case "current":
                                        return (
                                            (y.zoneData[currentTime]?.traffic || 0) -
                                            (x.zoneData[currentTime]?.traffic || 0)
                                        );
                                    case "peak":
                                        return y.peak.traffic - x.peak.traffic;
                                    default:
                                        return 0;
                                }
                            });

                            resolve({
                                date: dateToFetch,
                                processedData: processed,
                            });
                        } catch (error) {
                            console.error(`Error processing data for ${dateToFetch}:`, error);
                            reject(error);
                        }
                    }
                },
                authCtx.token
            );
        });

        const trafficPromise = new Promise((resolve, reject) => {
            getQueuesAPI(
                "get-clubhouse-daily-door-count",
                { locationId: 1, date: moment(dateToFetch).format("yyyy-MM-DD") },
                (trafficData, error) => {
                    if (error) {
                        if (error.status === 200) {
                            authCtx.logout();
                            navigate("/auth");
                        } else {
                            alert("Failed to fetch data. Please refresh the page and try again.");
                        }
                        reject(error);
                    } else {
                        try {
                            resolve(trafficData);
                        } catch (error) {
                            reject(error);
                        }
                    }
                },
                authCtx.token
            );
        });

        Promise.all([occupancyDataPromise, trafficPromise])
            .then(([occupancyResult, trafficResult]) => {
                const dataToCache = {
                    processedData: occupancyResult,
                    trafficData: trafficResult,
                };
                dataCache.current[dateToFetch] = dataToCache;
                saveCachedData(dateToFetch, dataToCache);

                setOverviewState((prevState) => ({
                    ...prevState,
                    dataList: [...prevState.dataList, occupancyResult],
                    trafficDataList: [...prevState.trafficDataList, trafficResult],
                    loading: false,
                }));
            })
            .catch((error) => {
                console.error("Error fetching data for graph:", error);
                setOverviewState((prevState) => ({ ...prevState, loading: false }));
            });
    };

    const handleClearSelectedGames = useCallback(() => {
        setOverviewState((prevState) => ({
            ...prevState,
            selectedDates: [],
            dataList: [],
            trafficDataList: [],
            gameTitlesList: [],
        }));
    }, []);

    const filteredGames = games.filter((x) => new Date() > moment(x.dates[0].date));

    const getButtonStyle = useCallback((isSelected, selectedColor) => ({
        backgroundColor: isSelected ? selectedColor : "",
        color: isSelected ? "#ffffff" : "",
    }), []);

    const totalTraffic = useMemo(() => {
        return overviewState.trafficDataList
            .reduce((total, dataSet) => {
                const dataSetTotal = dataSet.reduce((sum, item, index, array) => {
                    if (item.clear) {
                        return sum;
                    }
                    if (index === array.length - 1 || array[index + 1].clear) {
                        return sum + item.count;
                    }
                    return sum;
                }, 0);
                return total + dataSetTotal;
            }, 0)
            .toLocaleString();
    }, [overviewState.trafficDataList]);

    return (
        <div>
            {overviewState.loading && (
                <div
                    style={{ position: "fixed", top: 0, left: 300, right: 0, zIndex: 9999 }}
                >
                    <LinearProgress
                        sx={{
                            backgroundColor: "rgba(2, 166, 147, 0.25)",
                            height: 4,
                            "& .MuiLinearProgress-bar": {
                                backgroundColor: "rgb(2, 166, 147)",
                            },
                        }}
                    />
                </div>
            )}

            <div className="row mx-0">
                {/* First Column */}
                <div className="col-xl-5 col-12 mb-3 mb-xl-0">
                    <div
                        className="mt-3 p-3 panel-2"
                        style={{
                            height: "100%",
                            // backgroundColor: "white",
                            borderRadius: "10px",
                        }}
                    >
                        <div
                            style={{
                                fontSize: "24px",
                                paddingTop: "8px",
                                paddingRight: "16px",
                                paddingLeft: "16px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <span>Games</span>
                            {overviewState.selectedDates.length > 0 && (
                                <button
                                    onClick={handleClearSelectedGames}
                                    className="btn btn-sm"
                                >
                                    Clear
                                </button>
                            )}
                        </div>

                        <hr />

                        <ButtonGroup className="w-100">
                            <div className="w-100 mt-3">
                                {filteredGames.map((game, gameIndex) => {
                                    const startDate = game.dates?.[0]?.date;
                                    const endDate = game.dates?.[game.dates.length - 1]?.date;

                                    return (
                                        <Fragment key={`game-${game.teamID}-${gameIndex}`}>
                                            <div className="px-3 py-2 d-flex flex-column flex-md-row justify-content-between align-items-start">
                                                {/* Game Info */}
                                                <div className="d-flex align-items-center">
                                                    <img
                                                        alt="Opponent"
                                                        className="me-2"
                                                        width={20}
                                                        height={20}
                                                        src={getTeam(game.teamID).imgURL}
                                                    />
                                                    <span
                                                        className="me-2 fw-bold"
                                                        style={{ minWidth: '85px', display: 'inline-block' }}
                                                    >
                                                        {getTeam(game?.teamID)?.name}
                                                    </span>
                                                    <span>
                                                        {moment(startDate).format("MMM D")} - {moment(endDate).format("MMM D")}
                                                    </span>
                                                </div>

                                                {/* Buttons */}
                                                <div className="d-flex mt-2 mt-md-0">
                                                    {game.dates.map((gameDate, dateIndex) => {
                                                        const isSelected = overviewState.selectedDates.includes(gameDate.date);
                                                        let selectedColor = isSelected
                                                            ? overviewColors[overviewState.selectedDates.indexOf(gameDate.date)] || "#49AFA0"
                                                            : "";

                                                        const buttonStyle = getButtonStyle(isSelected, selectedColor);

                                                        return (
                                                            new Date() > moment(gameDate.date) && (
                                                                <Fragment key={gameDate.date}>
                                                                    <button
                                                                        disabled={overviewState.loading}
                                                                        onClick={() => {
                                                                            if (overviewState.selectedDates.length >= 4 && !isSelected) {
                                                                                alert("Maximum amount of games is 4.");
                                                                            } else {
                                                                                handleOverviewGameClick(gameDate, game);
                                                                            }
                                                                        }}
                                                                        className="btn mx-1 py-0"
                                                                        style={{ ...buttonStyle, minWidth: "40px" }}
                                                                    >
                                                                        {dateIndex + 1}
                                                                    </button>
                                                                </Fragment>
                                                            )
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        </ButtonGroup>
                    </div>
                </div>

                {/* Second Column */}
                <div className="col-xl-7 col-12">
                    <div
                        className="p-3 panel-2"
                        style={{
                            height: "100%",
                            // backgroundColor: "white",
                            borderRadius: "10px",
                            marginTop: "16px",
                            position: "relative",
                        }}
                    >
                        <div className="d-flex flex-column flex-lg-row justify-content-between align-items-center h-100 text-center">
                            {/* Total Traffic */}
                            <div
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: "42px",
                                        fontWeight: "bold",
                                        // color: "#333",
                                    }}
                                >
                                    {totalTraffic}
                                </div>
                                <div
                                    style={{
                                        marginTop: "8px",
                                        fontSize: "24px",
                                        color: "#555",
                                    }}
                                >
                                    Total Traffic
                                </div>
                            </div>

                            <OverviewTrafficPieChart
                                trafficDataSets={overviewState.trafficDataList}
                                dataSetNames={overviewState.gameTitlesList}
                                dataSetDates={overviewState.selectedDates}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div
                style={{
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    marginTop: "50px",
                }}
            >
                <OverviewOccupancyChart
                    dataSets={overviewState.dataList}
                    dataSetNames={overviewState.gameTitlesList}
                    dataSetDates={overviewState.selectedDates}
                />
                <OverviewRawChart
                    dataSets={overviewState.dataList}
                    dataSetNames={overviewState.gameTitlesList}
                    dataSetDates={overviewState.selectedDates}
                />
                <OverviewTrafficChart
                    trafficDataSets={overviewState.trafficDataList}
                    dataSetNames={overviewState.gameTitlesList}
                    dataSetDates={overviewState.selectedDates}
                    isCounterView={false}
                />
            </div>
        </div>
    );
}
