import { useContext } from "react";
import { NavContext } from "../../pages/Location";
import Icon from "../atoms/Icon";

export default function NavigationMenu({ className }) {
  const [currentSection, setCurrentSection, dayType] = useContext(NavContext);
  const sections = [
    { label: "Map", icon: "geo-fill" },
    dayType === "game" && { label: "Counter", icon: "person-standing" },
    { label: "Charts", icon: "bar-chart-fill" },
    { label: "Zones", icon: "puzzle-fill" },
  ];

  return (
    <div className={"nav nav-underline " + className}>
      {sections.map((sectionItem) => (
        <>
          {sectionItem && (
            <div className="nav-item">
              <a
                onClick={() => setCurrentSection(sectionItem.label)}
                className={
                  "nav-link" +
                  (currentSection === sectionItem.label ? " active" : "")
                }>
                <Icon name={sectionItem.icon} className="me-2" />
                {sectionItem.label}
              </a>
            </div>
          )}
        </>
      ))}
    </div>
  );
}
