import { useState, useEffect } from 'react';
import { getQueuesAPI } from '../../util';

export default function useOutageAlert(authToken) {
    const [showOutageAlert, setShowOutageAlert] = useState(false);

    const handleDismiss = () => setShowOutageAlert(false);

    useEffect(() => {
        getQueuesAPI(
            'get-clubhouse-current-data-dashboard',
            {},
            (data, _) => {
                if (data) {
                    const activeSensors = data.filter(sensor => sensor.sensorActive).length;
                    // const totalSensors = data.length;
                    // For the braves clubhouse store we are hard coding threshold for an outage
                    // Show outage when 13 or less sensors are operational
                    if (activeSensors <= 13) {
                        setShowOutageAlert(true);
                    }
                } else {
                    // Should be checking for error
                    // also should this console log be removed?
                    console.error('Failed to fetch current occupancy data.');
                }
            },
            authToken
        );
    }, [authToken]);

    return { showOutageAlert, handleDismiss };
}
