import React, { Fragment, useState, useContext } from 'react';
import { v4 as uuidv4 } from "uuid";
import Icon from "../../components/atoms/Icon";
import BravesClubhouseSvg from "../BravesClubhouseSvg.js";
import { Slider } from "@mui/material";
import moment from 'moment';
import { ThemeContext } from '../../components/molecules/Nav.js';


const ClubhouseMap = ({ 
  currentLocation,
  data,
  currentTime,
  setCurrentTime,
  isToday,
  dayType,
  currentGame,
  getTZOffset,
  peak,
  avg,
  findLabelByZoneId,
  getCurrentMinute,
  getMinutePosition,
  getDoorsOpenTime,
  isGameLater
}) => {
  const [hoveredItem, setHoveredItem] = useState(null);

  const [theme, setTheme] = useContext(ThemeContext);


  const calculateFontSize = (
    x,
    maxFontSize = 1.75,
    minFontSize = 1,
    maxLength = 6
  ) => {
    return Math.max(
      minFontSize,
      Math.min(
        maxFontSize,
        maxFontSize - (findLabelByZoneId(x.id).length - maxLength) * 0.1
      )
    );
  };

  return (
    <div className="row m-auto p-3 shadow fixed-h secondary" style={{ borderRadius: "36px" }}>
      <div className="col elevated-card-2" onPointerLeave={() => setHoveredItem(null)}>
        <div className="d-flex">
          <BravesClubhouseSvg>
            {currentLocation?.zones.map((x) => (
              <Fragment key={uuidv4()}>
                {x.shape.type === "polygon" ? (
                  <polygon
                    id={`zone-${x.id}`}
                    fill={x.id === hoveredItem?.id ? "#02a693" : "#B1B4B3"}
                    style={{ transition: "fill 0.3s ease-in-out, opacity 0.3s ease-in-out" }}
                    points={x?.shape?.points?.join(" ")}
                    onMouseEnter={() => setHoveredItem(x)}
                    onMouseLeave={() => setHoveredItem(null)}
                  />
                ) : x.shape.type === "path" ? (
                  <path
                    id={`zone-${x.id}`}
                    fill={x.id === hoveredItem?.id ? "#02a693" : "#B1B4B3"}
                    style={{ transition: "fill 0.3s ease-in-out, opacity 0.3s ease-in-out" }}
                    d={x.shape.d}
                    onMouseEnter={() => setHoveredItem(x)}
                    onMouseLeave={() => setHoveredItem(null)}
                  />
                ) : (
                  <rect
                    fill={x.id === hoveredItem?.id ? "#02a693" : ""}
                    style={{ opacity: x.id === hoveredItem?.id ? 1 : 0.26 }}
                    x={x.shape.x}
                    y={x.shape.y}
                    width={x.shape.width}
                    height={x.shape.height}
                  />
                )}
                <foreignObject
                  className="text-center fw-bold"
                  onMouseEnter={() => setHoveredItem(x)}
                  onMouseLeave={() => setHoveredItem(null)}
                  width={x.width}
                  height={x.height}
                  x={x.x}
                  y={x.y}
                  style={{
                    fontSize: `${calculateFontSize(x)}rem`,
                    cursor: "pointer",
                  }}
                  transform={`rotate(${x.rotation} ${x.x + 50} ${x.y + 50})`}>
                  <div
                    style={{
                      transform: `rotate(${-x.rotation}deg)`,
                      transformOrigin: `${x.toPersonX}px ${x.toPersonY}px`,
                      marginTop: `${x.personMargin}px`,
                    }}>
                    <span
                      style={{
                        ...(x.id === 10 && {
                          position: "relative",
                          right: "8px",
                        }),
                        ...(x.id === 6 && {
                          position: "relative",
                          right: "5px",
                          top: "5px",
                        }),
                        fontSize: "2.8rem",
                      }}>
                      {data?.[currentTime]?.traffic.filter(
                        (y) => y.id === x.id
                      )[0]?.traffic}
                    </span>
                  </div>
                </foreignObject>
              </Fragment>
            ))}

            {hoveredItem && (
              <foreignObject
                className="shadow p-4 rounded"
                style={{ backgroundColor: "#fafefe" }}
                width={250}
                height={200}
                x={hoveredItem.x + hoveredItem.hoverItemX}
                y={hoveredItem.y + hoveredItem.hoverItemY}>
                <div
                  className="text-center"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                  }}>
                  <div className="h5">Zone #{hoveredItem.id}</div>
                  <div className="h3">{findLabelByZoneId(hoveredItem.id)}</div>
                  <div className="h1">
                    <Icon name="person-standing" />
                    {data[currentTime]?.traffic.filter(
                      (y) => y.id === hoveredItem.id
                    )[0]?.traffic}
                  </div>
                </div>
              </foreignObject>
            )}
          </BravesClubhouseSvg>
        </div>
      </div>
      <div className="col elevated-card-2 fw-bold">
        <div className="d-flex h-100">
          <div className="w-100 m-auto text-center">
            <div className="" style={{ fontSize: "2.5rem" }}>
              Clubhouse Occupancy:
            </div>
            <div
              style={{
                fontSize: "4.3rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <Icon name="person-standing" className="me-2" />
              <span>{data[currentTime]?.total} / 191</span>
            </div>
            <div
              style={{
                color: data[currentTime]?.perc > 99 ? "red" : "inherit",
                fontSize: "2rem",
              }}>
              {data[currentTime]?.perc > 99 && (
                <Icon name="exclamation-triangle" className="me-3" />
              )}
              {data[currentTime]?.perc}% Full
            </div>
            <div className="mt-3">
              <div>{moment(data[currentTime]?.time2).format("LT")}</div>
              {!isGameLater() && dayType === "game" && (
                <div>Doors Open: {getDoorsOpenTime().format("LT")}</div>
              )}
              <Slider
                onChange={(e) => setCurrentTime(e.target.value)}
                value={currentTime}
                step={1}
                min={600}
                max={
                  isToday
                    ? getTZOffset() +
                    Math.round((moment(new Date()) - moment(new Date()).startOf("day")) / 60000) - 1
                    : data.length - 1
                }
                marks={[
                  {
                    value: 600,
                    label: "10 AM",
                  },
                  isGameLater() && {
                    value: getMinutePosition(`${currentGame?.date}T${currentGame?.doorsOpen}`),
                    label: `${getDoorsOpenTime().format("LT")} (Doors Open)`,
                  },
                  isToday
                    ? {
                      value: getCurrentMinute(),
                      label: moment(new Date()).startOf("day").add(getCurrentMinute(), "minutes").format("LT"),
                    }
                    : {
                      value: 1439,
                      label: "12 AM",
                    },
                ]}
                color="success"
                className="mt-4 w-100"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="m-auto">
        <div className="row elevated-card-3">
          {peak && (
            <div className="col-6">
              <div className="h2 text-center">
                <Icon name="caret-up-fill text-danger" className="me-2" />
                Peak:
                <hr className="mx-5" />
              </div>
              {dayType === "game" ? (
                <div className="text-center">
                  <small>Before Gates Open</small>
                  <div className="h2" style={{ color: "darkorange" }}>
                    <div className="">
                      <span>
                        {peak.preGame?.total}{" "}
                        {peak.preGame?.total === 1 ? "person" : "people"}{" "}
                        (at {moment(peak.preGame?.time2).format("LT")})
                      </span>
                      <span> - {peak.preGame?.perc}%</span>
                    </div>
                  </div>

                  {peak.midGame && (
                    <>
                      <small>After Gates Open</small>
                      <div className="h2" style={{ color: "darkorange" }}>
                        <div className="">
                          <span>
                            {peak.midGame?.total}{" "}
                            {peak.midGame?.total === 1 ? "person" : "people"}{" "}
                            (at {moment(peak.midGame?.time2).format("LT")})
                          </span>
                          <span> - {peak.midGame?.perc}%</span>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="text-center h2" style={{ color: "darkorange" }}>
                  <div className="">
                    <span>
                      {peak.wholeDay?.total}{" "}
                      {peak.wholeDay?.total === 1 ? "person" : "people"}{" "}
                      (at {moment(peak.wholeDay?.time2).format("LT")})
                    </span>
                    <span> - {peak.wholeDay?.perc}%</span>
                  </div>
                </div>
              )}
            </div>
          )}
          {avg && (
            <div className="col-6 text-center">
              <div className="h2">
                <Icon name="person-standing text-success" className="me-2" />
                Average:
              </div>
              <hr className="mx-5" />
              {dayType === "game" ? (
                <>
                  <small>Before Gates Open</small>
                  <div className="h2">
                    {avg.preGame} {avg.preGame === 1 ? "person" : "people"}{" "}
                    ({Math.round((avg.preGame / 190) * 100)}%)
                  </div>
                  {avg.startGame > 0 && (
                    <div>
                      <small>After Gates Open</small>
                      <div className="h2">
                        {avg.startGame} {avg.startGame === 1 ? "person" : "people"}{" "}
                        ({Math.round((avg.startGame / 190) * 100)}%)
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="h2">
                    {avg.wholeDay} {avg.wholeDay === 1 ? "person" : "people"}{" "}
                    ({Math.round((avg.wholeDay / 190) * 100)}%)
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClubhouseMap;