import {React, useContext} from 'react';
import { customMoment as moment } from "../../util";
import Button from "../../components/atoms/Button";
import ButtonGroup from "../../components/molecules/ButtonGroup";
import InputGroup from "../../components/molecules/InputGroup";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import Spinner from "../../components/atoms/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateRight } from "@fortawesome/free-solid-svg-icons";
import { ThemeContext } from '../../components/molecules/Nav';

const HeaderNav = ({
    currentLocation,
    loading,
    isToday,
    selectedDate,
    showCalendar,
    setShowCalendar,
    dayType,
    setDayType,
    refreshRegularDay
}) => {

    const [theme, setTheme] = useContext(ThemeContext);

    return (
        <div className="between">
            <div className="d-flex">
                <img
                    style={{ marginRight: "24px" }}
                    width={120}
                    src={require(theme === "light"
                        ? "../../assets/braves_clubhouse_store_logo.png"
                        : "../../assets/braves_clubhouse_store_logo_dark.png")}
                    alt="Braves Clubhouse Store Logo"
                />
                <span className="my-auto" style={{ fontSize: "2.5rem" }}>
                    {currentLocation?.name}
                </span>
            </div>

            <div className="my-auto">
                <InputGroup>
                    {isToday && selectedDate && (
                        <div className="d-flex align-items-center">
                            <span
                                style={{
                                    color: "red",
                                    marginRight: "12px",
                                    fontSize: "1.25rem",
                                }}>
                                LIVE
                            </span>
                            <span
                                className="pulsing-dot"
                                style={{ marginRight: "16px" }}></span>
                        </div>
                    )}
                    <ButtonGroup className="me-1">
                        <Button
                            onClick={() => {
                                if (showCalendar) {
                                    setShowCalendar(false);
                                }
                                setDayType("overview");
                            }}
                            disabled={loading}
                            icon={"graph-up"}
                            text="Overview"
                            className={`panel-2 btn ${dayType === "overview" ? "active" : ""}`}
                        />
                        {dayType === "regular" && (
                            <Button
                                className="panel-2"
                                icon="calendar"
                                text={
                                    moment(selectedDate).format("l") +
                                    (isToday ? " (Today)" : "")
                                }
                                onClick={() => setShowCalendar(!showCalendar)}
                            />
                        )}
                        <Button
                            onClick={() => setDayType("regular")}
                            disabled={loading}
                            icon={"sun-fill"}
                            text="Regular Day"
                            className={`panel-2 ${dayType === "regular" ? "active" : ""}`}
                        />
                        <button
                            onClick={() => setDayType("game")}
                            disabled={loading}
                            className={`panel-2 btn ${dayType === "game" ? "active" : ""}`}>
                            <SportsBaseballIcon
                                className="me-2"
                                style={{
                                    marginBottom: 4,
                                }}
                            />
                            <span
                                style={{
                                    marginTop: "4px",
                                    padding: 0,
                                    display: "inline-block",
                                }}>
                                Game Day
                            </span>
                        </button>
                    </ButtonGroup>
                    <ButtonGroup>
                        {isToday && selectedDate && (
                            <Button
                                className="border-0"
                                onClick={refreshRegularDay}
                                disabled={loading}>
                                {loading ? (
                                    <Spinner size={20} color={""} />
                                ) : (
                                    <div className="d-flex align-items-center">
                                        <FontAwesomeIcon icon={faArrowRotateRight} />
                                        <span style={{ marginLeft: "8px" }}>Refresh</span>
                                    </div>
                                )}
                            </Button>
                        )}
                    </ButtonGroup>
                </InputGroup>
            </div>
        </div>
    );
};

export default HeaderNav;