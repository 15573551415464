import React, { useState, useEffect, useRef, useCallback } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import 'chartjs-adapter-moment';
import zoomPlugin from 'chartjs-plugin-zoom';
import { Chart as ChartJS } from 'chart.js';

ChartJS.register(zoomPlugin);

const colors = [
  '#0000FF', '#FF0000', '#008000', '#FFA500', 
];

const getColorForIndex = (index) => {
  if (index < 0 || index >= colors.length) {
    return '#000000';
  }
  return colors[index];
};

const getIntervalKey = (incrementingValue) => {
    const baseDate = new Date();
    baseDate.setHours(0, 0, 0, 0);
    const newDate = new Date(baseDate.getTime() + incrementingValue * 60000);
    newDate.setMinutes(Math.floor(newDate.getMinutes() / 1) * 1, 0, 0);
    return newDate.toISOString();
  };
  
const OverviewRawChart = ({ dataSets, dataSetNames, dataSetDates }) => {
  const [dataValues, setDataValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);
  useEffect(() => {
    setLoading(true);
  
    const aggregatedDataSets = dataSets.map((dataSet) => {
        const dataMap = new Map();
        
        dataSet.processedData.forEach((item) => {
          if (item.time > 600) { // Filter out data with time <= 600
            const key = getIntervalKey(item.time);
            const entry = dataMap.get(key) || { total: 0 }; // Initialize total if not present
            
            entry.total += item.total; // Aggregate total
            
            dataMap.set(key, { ...entry, dateTime: key }); // Update the map with new aggregated total
          }
        });
      
        // Convert the Map into an array and format the data for return
        return Array.from(dataMap.values()).map(
          ({ dateTime, total }) => ({
            dateTime,
            total, // return total
          })
        );
      });
      

    setDataValues(aggregatedDataSets);
    setLoading(false);
  }, [dataSets]);
  
  


  const maxValue = Math.max(
    ...dataValues.flatMap((dataSet) => dataSet.map((item) => item.total))
  );

  const chartData = {
    labels: dataValues.length > 0 ? dataValues[0].map((item) => item.dateTime) : [],
    datasets: dataValues.map((dataSet, index) => ({
        label: dataSetNames[index] !== undefined && dataSetDates[index] !== undefined 
        ? `${dataSetNames[index]} · ${dataSetDates[index]}` 
        : dataSetNames[index] !== undefined 
        ? `${dataSetNames[index]}` 
        : '',
      data: dataSet.map((item) => ({ x: item.dateTime, y: item.total })),
      borderColor: getColorForIndex(index),
      backgroundColor: getColorForIndex(index),
      fill: false,
      pointRadius: 1, // lock at 1 due to high amount of data
      borderWidth: 2,
      tension: 0.4,
    })),
  };
  
  // Gets primary text color for chart
  const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-txt').trim();

  const chartOptions = {
    scales: {
      x: {
        title: {
            color: primaryTextColor,
            display: true,
            text: "Time",
            padding: { top: 0, bottom: 10 },
        },
        type: "time",
        time: {
            unit: "hour",
            displayFormats: {
              hour: "h A",
            },
          },
        grid: {
          display: false,
        },
        ticks: {
          source: "auto",
          color: primaryTextColor,
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        display: true,
        title: {
          color: primaryTextColor,
          display: true,
          text: "People",
          padding: { top: 0, bottom: 10 },
        },
        grid: {
          display: true,
        },
        ticks: {
            color: primaryTextColor,
            precision: 0,
            beginAtZero: true,
            stepSize: 20,
            callback: function (value) {
              if (value % 20 === 0) {
                return value;
              }
            },
          },
          max: Math.max(Math.ceil(maxValue / 20) * 20),
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
            title: (context) => {
                if (context.length == 0) {
                  return "";
                } else {
                  const label = context[0].dataset.label;
                  const labelParts = context[0].label.split(',');  // split the time
                  const pulledTime = labelParts.length > 2 ? labelParts[2].trim() : '';  // grab the time not date
                
                  return `${label}\n${pulledTime}`;
                }
            },
            label: (context) => ` ${context.raw.y}`,
        },
      },
      legend: {
        display: true,
        labels: {
          color: primaryTextColor,
          usePointStyle: true,
          pointStyle: 'rectRounded',
          filter: (legendItem, data) => {
            const index = legendItem.datasetIndex;
            return data.datasets[index] && data.datasets[index].data.length > 0;
          },
        },
      },
      zoom: {
        limits: {
          x: { min: 'original', max: 'original', minRange: 60 * 60 * 1000 },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
  };

  
  if (!loading) {
    return (
      <div
      className="panel-2" 
      style={{
        paddingRight: "24px",
        paddingLeft: "24px",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderRadius: "36px",
        marginTop: "16px",
        marginBottom: "16px",
        justifyContent: "center",
        // backgroundColor: "#fafefe",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        position: "relative",
      }}>
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "16px",
          marginRight: "16px"
        }}>
          <p style={{ fontSize: "24px" }}>
            Raw Occupancy
          </p>
          <div>
            <button
              style={{ marginRight: "5px" }}
              className={"btn border-1"}
              onClick={() => chartRef.current?.zoom(1.25)}
            >
              +
            </button>
            <button
              style={{ marginRight: "5px" }}
              className={"btn border-1"}
              onClick={() => chartRef.current?.zoom(0.75)}
            >
              -
            </button>
            <button
              className={"btn border-1"}
              onClick={() => chartRef.current?.resetZoom()}
            >
              Reset
            </button>
          </div>
        </div>
        <div style={{ position: 'relative', height: '300px', marginTop: '8px' }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowX: 'auto',
          }}>
            <div style={{ width: '100%', height: '100%' }}>
              <Line ref={chartRef} data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <p>Loading...</p>;
};

export default OverviewRawChart;
