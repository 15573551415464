import { createContext, useContext, useState, useEffect } from "react";
import React from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/auth-context";
import Icon from "../atoms/Icon";
import fullLogo from "../../assets/logo_1x.png";
import smallLogo from "../../assets/queues_icon.svg";
import { getChanges } from "../../util";

export const LoadingContext = createContext();
export const ThemeContext = createContext();

export default function Nav() {
  const authCtx = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useState(
    localStorage.getItem("queues-theme") || "light"
  );
  const navigate = useNavigate();

  var links = [
    {
      url: "locations",
      icon: "geo-fill",
      label: "Locations",
    },
  ];

  const logout = () => {
    authCtx.logout();
    navigate("/auth");
  };

  useEffect(() => {
    localStorage.setItem("queues-theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  return (
    <>
      <div className={"modal modal-lg"} id="changes">
        <div className="modal-dialog">
          <div className="modal-content p-3 elevated-card-2">
            <div className="p-4">
              {getChanges("").map((x, index) => (
                <div className="mb-5" key={index}>
                  <p className="h3" >
                    v{x.versionName} ({x.date})
                  </p>
                  <hr  />
                  <div className="mt-3">
                    {x.changes.map((y, yIndex) => (
                      <div className="d-flex mb-1" key={yIndex}>
                        <Icon name="dot"  />
                        <span className="badge mx-2">{y.type}</span>
                        <span >{y.description}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <LoadingContext.Provider value={[loading, setLoading]}>
        <div className="nav-main d-flex flex-column vh-100">
          <div className="container flex-grow-1 d-flex flex-column">
            <Link
              to="/locations"
              style={{ color: "#53b8aa" }}
              className="logo mb-4">
              <img
                alt="Full Logo"
                src={fullLogo}
                className="nav-logo-img full-logo"
              />
              <img
                alt="Small Logo"
                src={smallLogo}
                className="nav-logo-img small-logo"
              />
            </Link>

            <div className="flex-grow-1 d-flex flex-column justify-content-between">
              {authCtx.loggedIn && (
                <>
                  {links.map((link) => (
                    <Link
                      key={link.label}
                      className={`nav-item-custom nav-btn`}
                      to={"/" + link.url}>
                      <Icon name={link.icon} />
                      <span className="nav-label">{link.label}</span>
                    </Link>
                  ))}
                  <div>
                    {authCtx.loggedIn ? (
                      <>
                        <Link
                          className="nav-item-custom nav-btn w-100"
                          to={"/account"}>
                          <Icon name="person-fill" />
                          <span className="nav-label">{authCtx.firstName}</span>
                        </Link>
                      </>
                    ) : (
                      <Link to="/auth" className="btn w-100 mb-3">
                        <Icon name="person-fill" />
                        <span className="nav-label">Log In</span>
                      </Link>
                    )}
                    <hr />
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href="mailto:support@queues.com?subject=Braves Support - v1.0"
                      className="nav-item-custom nav-btn w-100">
                      <Icon name="question-circle" />
                      <span className="nav-label">Support</span>
                    </a>
                    <a
                      type="button"
                      data-bs-target="#changes"
                      data-bs-toggle="modal"
                      className="nav-item-custom nav-btn w-100 mt-3">
                      <Icon name="asterisk" />
                      <span className="nav-label">Changelog</span>
                    </a>
                    <div className="mb-3"></div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <ThemeContext.Provider value={[theme, setTheme]}>
          <Outlet />
        </ThemeContext.Provider>
      </LoadingContext.Provider>
    </>
  );
}
