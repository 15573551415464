export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based, so add 1
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
};

export const getDates = (series) => {
    let startDate = series?.[0].date;
    let endDate = series?.[series.length - 1].date;
    return [startDate, endDate];
};


export const getMostRecentSeriesAndGame = (data_, now) => {
    const today = now;

    // Filter series to only include those with dates in the past or today
    const pastOrTodaySeries = data_.filter((series) =>
        series.dates.some((game) => new Date(game.date) <= today)
    );

    // Sort the series by the most recent game date in each series
    const sortedSeries = pastOrTodaySeries.sort((a, b) => {
        const mostRecentGameA = new Date(
            Math.max(
                ...a.dates
                    .map((game) => new Date(game.date).getTime())
                    .filter((date) => date <= today.getTime())
            )
        );
        const mostRecentGameB = new Date(
            Math.max(
                ...b.dates
                    .map((game) => new Date(game.date).getTime())
                    .filter((date) => date <= today.getTime())
            )
        );
        return mostRecentGameB - mostRecentGameA;
    });

    // Get the most recent series
    const mostRecentSeriesFound = sortedSeries[0];

    // Find the most recent game in that series using the helper function
    const resultGame = resultgetSeriesAndGameByDate(
        mostRecentSeriesFound,
        now
    );

    return {
        recentSeries: mostRecentSeriesFound,
        recentGame: resultGame.recentGame,
    };
};

const resultgetSeriesAndGameByDate = (series, now) => {
    const today = formatDate(now);
    let mostRecentGame = null;

    // Find a game in the series that matches the selected date
    const todayGame = series.dates.find((game) => game.date === today);

    if (todayGame) {
        // If a game is found on the selected date, return the game
        // console.log('There is a game on the selected date:', today);
        // console.log('Game:', todayGame);
        return { recentGame: todayGame };
    }

    // Find the most recent game in the series that is before the selected date
    const recentGame = series.dates
        .filter((game) => game.date < today)
        .sort((a, b) => new Date(b.date) - new Date(a.date))[0];

    // Update the most recent game if found
    if (recentGame) {
        mostRecentGame = recentGame;
    }

    // If no game is found on the selected date, return the most recent game in the past
    if (mostRecentGame) {
        // console.log('No game found on the selected date:', today);
        // console.log('Selecting the most recent past game instead.');
        // console.log('Most Recent Game:', mostRecentGame);
        return { recentGame: mostRecentGame };
    }

    // If no past game is found, return null
    console.log("No recent past game found.");
    return { recentGame: null };
};
