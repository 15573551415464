import React, { useCallback, useState, useRef, useEffect } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import 'chartjs-adapter-moment';

const colors = [
  '#0000FF', '#FF0000', '#008000', '#FFA500', 
];

// Function to get color based on index
const getColorForIndex = (index) => {
  if (index < 0 || index >= colors.length) {
    return '#000000'; // Return black for out-of-bounds indices
  }
  return colors[index];
};


const OverviewOccupancyChart = ({ dataSets, dataSetNames, dataSetDates }) => {
  const [dataValues, setDataValues] = useState([]);
  const [chartResolution, setChartResolution] = useState(1);
  const [loading, setLoading] = useState(true);
  const resolutions = [1, 5, 10, 15, 30, 60];
  const scrollContainerRef = useRef(null);

  // Function to get the interval key based on resolution
  const getIntervalKey = (incrementingValue, resolution) => {
    const baseDate = new Date();
    baseDate.setHours(0, 0, 0, 0);
    const newDate = new Date(baseDate.getTime() + incrementingValue * 60000);
    newDate.setMinutes(Math.floor(newDate.getMinutes() / resolution) * resolution, 0, 0);
    return newDate.toISOString();
  };

  // Increase chart resolution and update data map
  const increaseWidth = () => {
    const currentIndex = resolutions.indexOf(chartResolution);
    if (currentIndex < resolutions.length - 1) {
      setChartResolution(resolutions[currentIndex + 1]);
      updateDataMap(resolutions[currentIndex + 1]);
    }
  };

  // Decrease chart resolution and update data map
  const decreaseWidth = () => {
    const currentIndex = resolutions.indexOf(chartResolution);
    if (currentIndex > 0) {
      setChartResolution(resolutions[currentIndex - 1]);
      updateDataMap(resolutions[currentIndex - 1]);
    }
  };

  // Reset chart resolution to default and update data map
  const resetWidth = () => {
    setChartResolution(resolutions[0]);
    updateDataMap(resolutions[0]);
  };

  // Update the data map based on the selected resolution
  const updateDataMap = useCallback((resolution = chartResolution) => {
    // Aggregate data for each dataSet
    const aggregatedDataSets = dataSets.map((dataSet) => {
      const dataMap = new Map();
  
      dataSet.processedData.forEach((item) => {
        if (item.time > 600) { // dont show data before this time
          const key = getIntervalKey(item.time, resolution);
          const entry = dataMap.get(key) || { totalPerc: 0, count: 0 };
  
          entry.totalPerc += item.perc;
          entry.count++;
  
          dataMap.set(key, { ...entry, dateTime: key });
        }
      });
  
      return Array.from(dataMap.values()).map(
        ({ dateTime, totalPerc, count }) => ({
          dateTime,
          perc: Math.round(totalPerc / count),
        })
      );
    });
    setDataValues(aggregatedDataSets);
    setLoading(false); // Set loading to false when data is fully loaded
  }, [dataSets, chartResolution]);
  

  // Compute the maximum value for the y-axis
  const maxValue = Math.max(
    ...dataValues.flatMap((dataSet) => dataSet.map((item) => item.perc))
  );

  // Prepare data for the chart
  const chartData = {
    labels: dataValues.length > 0 ? dataValues[0].map((item) => item.dateTime) : [],
    datasets: dataValues.map((dataSet, index) => ({
      label: dataSetNames[index] !== undefined && dataSetDates[index] !== undefined 
        ? `${dataSetNames[index]} · ${dataSetDates[index]}` 
        : dataSetNames[index] !== undefined 
        ? `${dataSetNames[index]}` 
        : '',
      data: dataSet.map((item) => item.perc),
      borderColor: getColorForIndex(index), 
      backgroundColor: getColorForIndex(index),
      fill: false,
      pointRadius: chartResolution >= 3 ? 3 : 1, // dynamically scale this up
      borderWidth: 2,
      tension: 0.4,
    })),
  };
  
  // Gets primary text color for chart
  const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-txt').trim();

  // Chart options
  const chartOptions = {
    scales: {
      x: {
        type: "time",
        title: {
          display: true,
          text: "Time",
          color: primaryTextColor,
        },
        time: {
          unit: "hour",
          displayFormats: {
            hour: "h A",
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          source: "auto",
          color: primaryTextColor,
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        title: {
          display: true,
          text: "Percentage",
          color: primaryTextColor,
        },
        ticks: {
          color: primaryTextColor,
          precision: 0,
          beginAtZero: true,
          stepSize: 20,
          callback: function (value) {
            if (value % 20 === 0) {
              return value;
            }
          },
        },
        max: Math.max(Math.ceil(maxValue / 20) * 20),
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          title: (context) => {
            if (context.length == 0) {
              return "";
            } else {
              const label = context[0].dataset.label;
              const labelParts = context[0].label.split(',');  // split the time
              const pulledTime = labelParts.length > 2 ? labelParts[2].trim() : '';  // grab the time not date
            
              return `${label}\n${pulledTime}`;
            }
          },
          label: (context) => {
            // console.log(context);
            // context.label = 'hello';
            
            return ` ${context.raw}%`;
          },
        },
      },
      legend: {
        display: true,
        labels: {
          color: primaryTextColor,
          usePointStyle: true, 
          pointStyle: 'rectRounded',
          filter: (legendItem, data) => {
            const index = legendItem.datasetIndex;
            return data.datasets[index] && data.datasets[index].data.length > 0;
          },
        },
      },
    },
  };

  // UseEffect to update data map when dataSets or chartResolution changes
  useEffect(() => {
    setLoading(true);
    updateDataMap();
  }, [updateDataMap]);

  // Render the chart or a loading message
  if (!loading) {
    return (
      <div 
      className="panel-2"
      style={{
        paddingRight: "24px",
        paddingLeft: "24px",
        paddingTop: "8px",
        paddingBottom: "8px",
        borderRadius: "36px",
        marginTop: "16px",
        marginBottom: "16px",
        justifyContent: "center",
        // backgroundColor: "#fafefe",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      }}>
        <div style={{
           display: "flex",
           alignItems: "center",
           justifyContent: "space-between",
           marginTop: "16px",
           marginRight: "16px"
        }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ fontSize: "24px" }}>
              Average Total Occupancy %
            </p>
          </div>
          <div>
            <span style={{ fontSize: "16px", margin: "0 20px" }}>
              Bar Scale: {chartResolution} mins.
            </span>
            <button
              style={{ marginRight: "5px" }}
              className={"btn border-1"}
              onClick={increaseWidth}
              disabled={resolutions.indexOf(chartResolution) >= 5}
            >
              +
            </button>
            <button
              style={{ marginRight: "5px" }}
              className={"btn border-1"}
              onClick={decreaseWidth}
              disabled={resolutions.indexOf(chartResolution) <= 0}
            >
              -
            </button>
            <button
              className={"btn border-1"}
              onClick={resetWidth}
              disabled={chartResolution === 5}
            >
              Reset
            </button>
          </div>
        </div>
        <div style={{ 
          position: 'relative',
          height: '300px',
          marginTop: '8px',
        }}>
          <div
            ref={scrollContainerRef}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflowX: 'auto',
            }}
          >
            <div style={{
              width: `100%`,
              height: '100%'
            }}>
              <Line data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <p>Loading...</p>;
};

export default OverviewOccupancyChart;
