import React, { useState, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import 'chartjs-adapter-moment';
import { Chart as ChartJS } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJS.register(zoomPlugin);

const RawChart = ({ data, dayType }) => {
  const [dataValues, setDataValues] = useState([]);
  const [loading, setLoading] = useState(true); // New loading state
  const chartRef = useRef(null);

  useEffect(() => {
    setLoading(true); // Set loading to true when data processing starts

    const updatedData = data
      .filter((entry) => entry.time > 600)
      .map((entry) => {
        const dateTime = new Date(data[0].time2);
        dateTime.setHours(0, entry.time, 0, 0);
        return {
          ...entry,
          time: dateTime,
        };
      });

    setDataValues(updatedData);
    setLoading(false); // Set loading to false when data processing is done
  }, [data]);

  const maxValue = Math.max(...dataValues.map((item) => item.total));

  const chartData = {
    labels: dataValues.map((item) => item.time),
    datasets: [
      {
        label: "Head Count",
        data: dataValues.map((item) => item.total),
        backgroundColor: dataValues.map((item) => (item.total >= 190 ? "red" : "#53b8aa")),
        barThickness: barWidth,
        borderRadius: 2.5,
      },
    ],
  };

  // Gets primary text color for chart
  const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-txt').trim();

  const mainChartOptions = {
    scales: {
      x: {
        title: {
          color: primaryTextColor,
          display: true,
          text: "Time",
          padding: { top: 0, bottom: 10 },
        },
        type: "time",
        time: {
          unit: "hour",
          displayFormats: {
            hour: "h A",
          },
        },
        grid: {
          display: false,
        },
        ticks: {
          color: primaryTextColor,
          source: "auto",
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        display: true,
        title: {
          color: primaryTextColor,
          display: true,
          text: "People",
          padding: { top: 0, bottom: 10 },
        },
        grid: {
          display: false,
        },
        ticks: {
          color: primaryTextColor,
          precision: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
        max: maxValue,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      zoom: {
        limits: {
          x: { min: 'original', max: 'original', minRange: 60 * 60 * 1000 },
        },
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
  };

  var barWidth = 3;

  const handleResetZoom = () => {
    if (chartRef.current) {
      barWidth = 3;
      chartRef.current.resetZoom();
    }
  };

  const handleZoomIn = () => {
    if (chartRef.current && barWidth < 8) {
      barWidth += 1;
      chartRef.current.zoom(1.25);
    }
  };

  const handleZoomOut = () => {
    if (chartRef.current && barWidth > 0) {
      barWidth -= 1;
      chartRef.current.zoom(0.75);
    }
  };

  if (!loading) {
    return (
      <div
        className="panel-2"
        style={{
          paddingRight: "24px",
          paddingLeft: "24px",
          paddingTop: "8px",
          paddingBottom: "8px",
          borderRadius: "36px",
          marginTop: "16px",
          marginBottom: "16px",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "#fafefe",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "16px",
            marginLeft: '8px',
            marginRight: "16px"
          }}
        >
          <p
            style={{
              fontSize: "24px",
            }}
          >
            Raw Occupancy
          </p>
          <div>
            <button
              style={{
                marginRight: "5px",
              }}
              className={"btn border-1"}
              onClick={handleZoomIn}>
              +
            </button>
            <button
              style={{
                marginRight: "5px",
              }}
              className={"btn border-1"}
              onClick={handleZoomOut}>
              -
            </button>
            <button
              className={"btn border-1"}
              onClick={handleResetZoom}>
              Reset
            </button>
          </div>
        </div>
    
        <div style={{ overflowX: "auto", marginTop: "8px" }}>
          <div style={{ height: "300px", minWidth: "600px" }}>
            <Bar ref={chartRef} data={chartData} options={mainChartOptions} />
          </div>
        </div>
      </div>
    );
  };
}

export default RawChart;