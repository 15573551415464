import {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  DOORS_OPEN_TIME,
  GAME_END_CUTOFF,
  SORT_OPTIONS,
  DOORS_OPEN_BUFFER,
  GAME_END_BUFFER,
  DAY_TYPES,
  SECTION_TYPES,
  DEFAULT_ZONE_LABEL,
  MAX_OCCUPANCY,
  CALENDAR_MIN_DATE,
} from './location/constants.js';

import { getMostRecentSeriesAndGame } from "./location/helper_functions.js";

import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../contexts/auth-context";
import Button from "../components/atoms/Button";
import ButtonGroup from "../components/molecules/ButtonGroup";
import OccupancyChart from "../components/atoms/OccupancyChart";

import RawChart from "../components/atoms/RawChart";
import ZoneItem from "../components/organisms/ZoneItem";
import Counter from "../components/organisms/Counter.js";
import {
  customMoment as moment,
  formatTimestamp,
  generateDay,
  getData,
  getQueuesAPI,
  getTZOffset,
} from "../util";
import Dropdown from "../components/atoms/Dropdown";
import Spinner from "../components/atoms/Spinner";
import Calendar from "react-calendar";
import { v4 as uuidv4 } from "uuid";
import "react-calendar/dist/Calendar.css";
import OutageAlert from "../components/atoms/OutageAlert.js";
import Overview from "./Overview.js";
import useOutageAlert from "../components/atoms/OutageAlertHook.js";
import NavigationMenu from "../components/molecules/NavigationMenu.js";
import GameSelector from "./location/game_selector.js";
import HeaderNav from "./location/header_nav.js";
import ClubhouseMap from "./location/clubhouse_map.js";
import { ThemeContext } from "../components/molecules/Nav.js";

export const MapContext = createContext();
export const NavContext = createContext();

export default function Location() {
  const location = useLocation();
  const { state } = location;
  const id = state?.id;
  const navigate = useNavigate();

  const authCtx = useContext(AuthContext);
  const { showOutageAlert, handleDismiss } = useOutageAlert(authCtx.token);

  const [loading, setLoading] = useState(false);
  const [theme, setTheme] = useContext(ThemeContext);

  const [data, setData] = useState([]);
  const [games, setGames] = useState([]);
  const [zones, setZones] = useState([]);
  const [now, setNow] = useState(new Date());

  const [currentLocation, setCurrentLocation] = useState(null);
  const [currentSeries, setCurrentSeries] = useState(null);
  const [currentGame, setCurrentGame] = useState(null);
  const [currentSection, setCurrentSection] = useState(SECTION_TYPES.MAP);
  const [currentSeason, setCurrentSeason] = useState("2024");

  const [currentTime, setCurrentTime] = useState(DOORS_OPEN_TIME);

  const [dayType, setDayType] = useState(DAY_TYPES.GAME);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showCalendar, setShowCalendar] = useState(false);

  const [peak, setPeak] = useState(null);
  const [avg, setAvg] = useState(null);

  const [sort, setSort] = useState("zone-id");
  const [showAllMaps, setShowAllMaps] = useState(false);
  const [isToday, setIsToday] = useState(false);

  const isGameLater = () => {
    let endCutoff = isToday ? getCurrentMinute() - 100 : GAME_END_CUTOFF;
    return (
      dayType === DAY_TYPES.GAME &&
      getMinutePosition(`${currentGame?.date}T${currentGame?.doorsOpen}`) >
      703 &&
      getMinutePosition(`${currentGame?.date}T${currentGame?.doorsOpen}`) <
      endCutoff
    );
  };

  useEffect(() => {
    if (data.length !== 0 && currentGame) {
      let doorsOpenTime =
        Math.round(
          (getDoorsOpenTime().toDate() -
            moment(currentGame.date).startOf("day").toDate()) /
          60000
        ) - 1;

      let gameStartTime =
        Math.round(
          (getGameStartTime().toDate() -
            moment(currentGame.date).startOf("day").toDate()) /
          60000
        ) - 1;

      let nowCutoff =
        Math.round(
          (moment(now).toDate() - moment(now).startOf("day").toDate()) / 60000
        ) + getTZOffset();

      //- Two average calculations:
      //    1. 10 am --> doorsOpen - 30 mins
      //    2. doorsOpen --> gameStartTime + 3.5 hrs
      let beforeGame = data.slice(DOORS_OPEN_TIME, doorsOpenTime - DOORS_OPEN_BUFFER);
      let beforeGameAvg = Math.round(
        beforeGame.reduce((x, y) => x + y.total, 0) / beforeGame.length
      );

      let midGame = data.slice(doorsOpenTime, gameStartTime + GAME_END_BUFFER);
      let midGameAvg = Math.round(
        midGame.reduce((x, y) => x + y.total, 0) / midGame.length
      );

      let filtered = data.slice(
        DOORS_OPEN_TIME,
        isToday && DOORS_OPEN_TIME < nowCutoff ? nowCutoff : undefined
      );

      let max = 0;
      let max2 = 0;
      let max3 = 0;
      let result = { preGame: null, startGame: null, wholeDay: null };

      for (let x = 0; x < beforeGame.length; x++) {
        if (beforeGame[x].perc > max) {
          max = beforeGame[x].perc;
          result.preGame = beforeGame[x];
        }
      }

      for (let x = 0; x < midGame.length; x++) {
        if (midGame[x].perc > max2) {
          max2 = midGame[x].perc;
          result.midGame = midGame[x];
        }
      }

      for (let x = 0; x < filtered.length; x++) {
        if (filtered[x].perc > max3) {
          max3 = filtered[x].perc;
          result.wholeDay = filtered[x];
        }
      }

      setPeak(result);
      setAvg(
        dayType === DAY_TYPES.GAME
          ? { preGame: beforeGameAvg, startGame: midGameAvg }
          : {
            wholeDay: Math.round(
              filtered.reduce((x, y) => x + y.total, 0) / filtered.length
            ),
          }
      );
    }
  }, [data]);

  const fetchData = () => {
    setLoading(true);

    setNow(new Date());
    // This is the first/main data call
    getQueuesAPI(
      "get-clubhouse-daily-data",
      { locationId: 1, date: moment(selectedDate).format("yyyy-MM-DD") },
      (data_, error) => {
        // We need to check for error in this data call because the data is pulled instantly
        // on app load here for the current date (today)
        if (error) {
          // Sometimes user is logged out/token expired so we get 401
          // Not logged in / invalid token - unauthorized
          if (error.status === 401) {
            authCtx.logout();
            navigate("/auth");
          } else {
            // Handle errors that arent 401
            alert(
              "Failed to fetch data. Please refresh the page and try again."
            );
          }
        } else {
          const zones_ = currentLocation.zones.map((currentZone, index) => {
            let currentData = [];
            try {
              if (
                data_ &&
                data_[index] &&
                data_[index][currentZone.id.toString()]
              ) {
                currentData = data_[index][currentZone.id.toString()];
              } else {
                console.warn(
                  `No data found for zone ${currentZone.id} at index ${index}`
                );
              }
            } catch (error) {
              console.error(
                `Error processing data for zone ${currentZone.id}:`,
                error
              );
            }

            const formattedData = currentData.map((item, timeIndex) => ({
              zone: currentZone.id,
              time: timeIndex,
              time2: item?.time || "",
              traffic: item?.occupancy || 0,
            }));

            const peak = formattedData.reduce(
              (max, item) => (item.traffic > max.traffic ? item : max),
              { traffic: -Infinity }
            );

            return {
              zoneId: currentZone.id,
              zoneData: formattedData,
              label: currentZone.label,
              x: currentZone.x,
              y: currentZone.y,
              redDotX: currentZone.circleX, // Map red dot x
              redDotY: currentZone.circleY, // Map red dot y
              peak,
            };
          });

          const day = generateDay(selectedDate);
          const zoneDataMap = new Map(
            zones_.map((zone) => [zone.zoneId, zone.zoneData])
          );

          const processed = day.reduce((acc, currentMinute, x) => {
            const time = new Date(currentMinute);

            const formattedTime = formatTimestamp(time);

            const minuteData = zones_
              .map((zone) => {
                const match = zoneDataMap
                  .get(zone.zoneId)
                  .find((z) => z.time2 === formattedTime);
                return match
                  ? { traffic: match.traffic, id: zone.zoneId }
                  : null;
              })
              .filter(Boolean);

            const total = minuteData.reduce(
              (sum, item) => sum + item.traffic,
              0
            );
            const perc = Math.round((total / MAX_OCCUPANCY) * 100);

            acc.push({
              time: x,
              time2: currentMinute,
              total,
              perc,
              traffic: minuteData,
            });

            return acc;
          }, []);

          zones_.sort((x, y) => {
            switch (sort) {
              case "zone-id":
                return x.zoneId - y.zoneId;
              case "current":
                return (
                  (y.zoneData[currentTime]?.traffic || 0) -
                  (x.zoneData[currentTime]?.traffic || 0)
                );
              case "peak":
                return y.peak.traffic - x.peak.traffic;
              default:
                return 0;
            }
          });
          setZones(zones_);
          setData(processed);
          setLoading(false);
        }
      },
      authCtx.token
    );
  };

  const getDoorsOpenTime = () =>
    moment(`${currentGame?.date}T${currentGame?.doorsOpen}`);

  const getGameStartTime = () =>
    moment(`${currentGame?.date}T${currentGame?.gameStartTime}`);

  const getCurrentMinute = () =>
    getTZOffset() +
    Math.round(
      (moment(now).toDate() - moment(now).startOf("day").toDate()) / 60000
    ) -
    1;

  const getMinutePosition = (date_) =>
    getTZOffset() +
    Math.round(
      (moment(date_).toDate() - moment(date_).startOf("day").toDate()) / 60000
    ) -
    1;

  useEffect(() => {
    if (currentLocation && selectedDate) {
      fetchData();
    }
  }, [currentLocation, sort, dayType, selectedDate]);

  useEffect(() => {
    if (authCtx.loggedIn) {
      setCurrentLocation(getData(id));
    }
  }, [id, authCtx.loggedIn]);

  useEffect(() => {
    if (dayType === DAY_TYPES.GAME) {
      setSelectedDate(null);
      setCurrentSeries(null);
      setCurrentGame(null);
      setShowCalendar(false);
    } else {
      let allDates = [];
      let lastNonGame = [];
      for (let x = 0; x < games.length; x++) {
        for (let y = 0; y < games[x].dates.length; y++) {
          let curr = moment(games[x].dates[y].date).startOf("day");
          // console.log(`${moment(now).startOf('day').toDate()} and ${curr.toDate()}`)
          if (moment(now).startOf("day").toDate() >= curr.toDate()) {
            allDates.push(curr.format());
          }
        }
      }

      allDates.sort((x, y) => moment(y).toDate() - moment(x).toDate());
      // console.log(allDates);
      for (let z = 0; z < 30; z++) {
        if (
          !allDates.includes(
            moment(now).startOf("day").subtract(z, "days").format()
          )
        ) {
          lastNonGame.push(
            moment(now).startOf("day").subtract(z, "days").format()
          );
          // break;
        }
      }
      setSelectedDate(moment(lastNonGame[0]).toDate());
      // console.log(lastNonGame);

      setCurrentTime(
        getTZOffset() +
        Math.round(
          (moment(now).toDate() - moment(now).startOf("day").toDate()) / 60000
        ) -
        1
      );
      dayType === DAY_TYPES.REGULAR && setCurrentSection(SECTION_TYPES.MAP);
    }
  }, [dayType]);

  // TODO Make sure this runs every time we select a new page or game
  useEffect(() => {

    // console.log("REBUILDING")

    if (currentGame) {
      setCurrentTime(
        isToday
          ? getTZOffset() +
          Math.round((moment(now) - moment(now).startOf("day")) / 60000) -
          1
          : DOORS_OPEN_TIME
      );
      setSelectedDate(moment(currentGame?.date).toDate());
    }
  }, [currentGame]);

  useEffect(() => {
    if (
      (dayType === DAY_TYPES.GAME &&
        moment(currentGame?.date).startOf("day").format() ===
        moment(now).startOf("day").format()) ||
      (dayType === DAY_TYPES.REGULAR &&
        moment(selectedDate).startOf("day").format() ===
        moment(now).startOf("day").format())
    ) {
      setIsToday(true);
    } else {
      setIsToday(false);
    }
  }, [dayType, currentGame, selectedDate]);

  // This is not the first data call
  const getGames = () => {
    getQueuesAPI(
      "get-braves-current-schedule",
      {},
      (data_, error) => {
        // We need to check for error here because sometimes site is loaded
        // and first data call might not execute (this always does to get scheudle)
        if (error) {
          // Sometimes user is logged out/token expired so we get 401
          // Not logged in / invalid token - unauthorized
          if (error.status === 401) {
            authCtx.logout();
            navigate("/auth");
          } else {
            // Handle errors that arent 401
            alert(
              "Failed to fetch data. Please refresh the page and try again."
            );
          }
        } else {
          // data_.push({
          //   dates: [
          //     {
          //       date: "2024-11-13",
          //       doorsOpen: "05:10",
          //       gameStartTime: "12:00",
          //     },
          //     {
          //       date: "2024-11-14",
          //       doorsOpen: "17:33",
          //       gameStartTime: "17:50",
          //     },
          //   ],
          //   gamesInSeries: 1.0,
          //   teamID: 20.0,
          //   teamName: "Phillies",
          // });
          setGames(
            data_.filter(
              (x) => moment(x.dates[0].date).format("YYYY") === currentSeason
            )
          );
          const { recentSeries, recentGame } =
            getMostRecentSeriesAndGame(data_, now);
          setCurrentSeries(recentSeries);
          setCurrentGame(recentGame);
        }
      },
      authCtx.token
    );
  };

  useEffect(() => {
    dayType === DAY_TYPES.GAME && getGames();
  }, [dayType]);

  useEffect(() => {
    getGames();
  }, [currentSeason]);

  const refreshRegularDay = () => {
    fetchData();
    setCurrentTime(
      Math.round((moment(now) - moment(now).startOf("day")) / 60000) - 1
    );
  };

  // Navigate to the locations page if id is null
  useEffect(() => {
    if (!id) {
      navigate("/locations");
    }
  }, [id, navigate]);

  // Finds the user preference zone label given a zone id
  const findLabelByZoneId = (zoneId) => {
    const preference = authCtx.userPreferences.find(
      (pref) => pref.zoneId === zoneId
    );
    return preference ? preference.label : DEFAULT_ZONE_LABEL; 
  };

  return (
    <>
      {authCtx.loggedIn ? (
        <>
          <div className="sticky-top pt-3">
            <div className="px-3">
              <HeaderNav
                currentLocation={currentLocation}
                loading={loading}
                isToday={isToday}
                selectedDate={selectedDate}
                showCalendar={showCalendar}
                setShowCalendar={setShowCalendar}
                dayType={dayType}
                setDayType={setDayType}
                refreshRegularDay={refreshRegularDay}
              />

              {dayType === DAY_TYPES.GAME && (
                <GameSelector
                  loading={loading}
                  currentSeries={currentSeries}
                  currentGame={currentGame}
                  currentSeason={currentSeason}
                  setCurrentSeason={setCurrentSeason}
                  games={games}
                  now={now}
                  setCurrentGame={setCurrentGame}
                  setCurrentSeries={setCurrentSeries}
                  setSelectedDate={setSelectedDate}
                  theme={theme}
                />
              )}
              <>
                {showCalendar && (
                  <Calendar
                    tileDisabled={({ activeStartDate, date, view }) => {
                      let allDates = [];

                      for (let x = 0; x < games.length; x++) {
                        for (let y = 0; y < games[x].dates.length; y++) {
                          allDates.push(
                            moment(games[x].dates[y].date)
                              .startOf("day")
                              .format()
                          );
                        }
                      }

                      return allDates.includes(
                        moment(date).startOf("day").format()
                      );
                    }}
                    className="w-75 rounded mx-auto mt-4"
                    onChange={(date) => {
                      setSelectedDate(date);
                      setShowCalendar(false);
                    }}
                    value={selectedDate}
                    minDate={moment(CALENDAR_MIN_DATE).toDate()}
                    maxDate={now}
                    showNeighboringMonth={false}
                  />
                )}
              </>
            </div>
            <hr />
          </div>
          {loading ? (
            <div className="d-flex" style={{ height: "400px" }}>
              <Spinner className="m-auto" size={160} />
            </div>
          ) : (
            // Main content for Regular and Game Days
            <>
              {selectedDate && (dayType === DAY_TYPES.REGULAR || dayType === DAY_TYPES.GAME) ? (
                <>
                  {getCurrentMinute() < DOORS_OPEN_TIME && isToday ? (
                    <div style={{ height: "500px" }}>
                      <div className="m-auto w-100 text-center">
                        Opens At 10:00 AM EST
                      </div>
                    </div>
                  ) : (
                    <div className="py-3 px-5">
                      <div className="d-flex">
                        <div className="w-100">
                          <div className="d-flex">
                            <NavContext.Provider
                              value={[
                                currentSection,
                                setCurrentSection,
                                dayType,
                              ]}>
                              <NavigationMenu className="mx-auto mb-4" />
                            </NavContext.Provider>
                          </div>
                          {[SECTION_TYPES.ALL, SECTION_TYPES.MAP].includes(currentSection) && (
                            <ClubhouseMap
                              currentLocation={currentLocation}
                              data={data}
                              currentTime={currentTime}
                              setCurrentTime={setCurrentTime}
                              isToday={isToday}
                              dayType={dayType}
                              currentGame={currentGame}
                              getTZOffset={getTZOffset}
                              peak={peak}
                              avg={avg}
                              findLabelByZoneId={findLabelByZoneId}
                              getCurrentMinute={getCurrentMinute}
                              getMinutePosition={getMinutePosition}
                              getDoorsOpenTime={getDoorsOpenTime}
                              isGameLater={isGameLater}
                            />
                          )}
                        </div>
                      </div>
                      {dayType === DAY_TYPES.GAME &&
                        selectedDate !== null &&
                        [SECTION_TYPES.ALL, SECTION_TYPES.COUNTER].includes(currentSection) && (
                          <div className="fixed-h">
                            <Counter
                              isToday={isToday}
                              selectedDate={selectedDate}
                              currentGame={currentGame}
                            />
                          </div>
                        )}

                      {data.length !== 0 &&
                        [SECTION_TYPES.ALL, SECTION_TYPES.CHARTS].includes(currentSection) && (
                          <div className="fixed-h">
                            <OccupancyChart data={data} dayType={dayType} />
                            <RawChart data={data} dayType={dayType} />
                          </div>
                        )}
                      {[SECTION_TYPES.ALL, SECTION_TYPES.ZONES].includes(currentSection) && (
                        <div className="fixed-h">
                          <div className={"between"}>
                            <div>
                              <Button text="Sort:" className="border-0" />
                              <ButtonGroup>
                                <Dropdown
                                  classNameBtn="btn"
                                  target="sorts"
                                  className="btn-group"
                                  text={
                                    sort === "zone-id"
                                      ? "Zone ID"
                                      : sort === "current"
                                        ? "Current Occupancy"
                                        : "Peak Occupancy"
                                  }
                                  icon="filter-left">
                                  {SORT_OPTIONS.map((x) => (
                                    <Fragment key={uuidv4()}>
                                      {x.code !== sort && (
                                        <button
                                          onClick={() => setSort(x.code)}
                                          className="dropdown-item">
                                          {x.name}
                                        </button>
                                      )}
                                    </Fragment>
                                  ))}
                                </Dropdown>
                                <Button
                                  onClick={() => setShowAllMaps(!showAllMaps)}
                                  icon={showAllMaps ? "eye-slash" : "eye"}
                                  text={
                                    (showAllMaps ? "Hide" : "Show") +
                                    " All Maps"
                                  }
                                  className="border-0"
                                />
                              </ButtonGroup>
                            </div>
                          </div>
                          <MapContext.Provider
                            value={[showAllMaps, setShowAllMaps]}>
                            <div className="row mt-3">
                              {zones.map((x) => (
                                <ZoneItem
                                  key={x.zoneId}
                                  currentSeries={currentSeries}
                                  currentGame={currentGame}
                                  dayType={dayType}
                                  x={x}
                                  data={
                                    data[currentTime]?.traffic.filter(
                                      (y) => y.id === x.zoneId
                                    )[0]?.traffic || 0
                                  }
                                />
                              ))}
                            </div>
                          </MapContext.Provider>
                        </div>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <Overview
                  currentLocation={currentLocation}
                  currentTime={currentTime}
                  sort={sort}
                  games={games}
                />
              )}
            </>
          )}
        </>
      ) : (
        <div className="p-4">
          <Link to={"/auth"}>
            Not Authorized. Please Log-In with given credentials or contact
            support.
          </Link>
        </div>
      )}

      <OutageAlert show={showOutageAlert} onDismiss={handleDismiss} />
    </>
  );
}
