import { createContext, useState } from "react";

const AuthContext = createContext();

export const AuthContextProvider = (props) => {
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [firstName, setFirstName] = useState(localStorage.getItem("firstName"));
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [tokenExpirationTime, setTokenExpirationTime] = useState(
    localStorage.getItem("tokenExpirationTime")
  );
  const [authLocations, setAuthLocations] = useState(
    JSON.parse(localStorage.getItem("authLocations"))
  );
  const [doorCountingEnabled, setDoorCountingEnabled] = useState(localStorage.getItem("doorCountingEnabled"));
  const [dashboardEnabled, setDashboardEnabled] = useState(localStorage.getItem("dashboardEnabled"));
  const [userPreferences, setUserPreferences] = useState(
    JSON.parse(localStorage.getItem("userPreferences"))
  );
  // Uncomment if crash - this clears the local storage should work tho for fresh uses
  // const [userPreferences, setUserPreferences] = useState(() => {
  //   const savedPreferences = localStorage.getItem("userPreferences");
  //   console.log('Saved Preferences:', savedPreferences); // Debugging log
  //   if (savedPreferences) {
  //     try {
  //       return JSON.parse(savedPreferences);
  //     } catch (error) {
  //       console.error('Error parsing JSON:', error);
  //       return {}; // Return a default value if parsing fails
  //     }
  //   } else {
  //     return {}; // Return a default value if no preferences are saved
  //   }
  // });

  const login = (
    email,
    firstName,
    token,
    tokenExpirationTime,
    authLocations,
    doorCountingEnabled,
    dashboardEnabled,
    userPreferences
  ) => {
    setEmail(email);
    setFirstName(firstName);
    setToken(token);
    setTokenExpirationTime(tokenExpirationTime);
    setAuthLocations(authLocations);
    setDoorCountingEnabled(doorCountingEnabled);
    setDashboardEnabled(dashboardEnabled);
    setUserPreferences(userPreferences);
    localStorage.setItem("email", email);
    localStorage.setItem("firstName", firstName);
    localStorage.setItem("token", token);
    localStorage.setItem("tokenExpirationTime", tokenExpirationTime);
    localStorage.setItem("authLocations", JSON.stringify(authLocations));
    localStorage.setItem("doorCountingEnabled", doorCountingEnabled);
    localStorage.setItem("dashboardEnabled", dashboardEnabled);
    localStorage.setItem("userPreferences", JSON.stringify(userPreferences));
  };

  // Updates the users preferences
  const updateUserPreferences = (preferences) => {
    setUserPreferences(preferences)
    localStorage.setItem("userPreferences", JSON.stringify(preferences));
    // console.log('LocalStorage: User Preferences Updated')
  }

  const logout = () => {
    setEmail(null);
    setFirstName(null);
    setToken(null);
    setTokenExpirationTime(null);
    setAuthLocations([]);
    setDoorCountingEnabled(null);
    setDashboardEnabled(null);
    setUserPreferences([]);
    localStorage.removeItem("email");
    localStorage.removeItem("firstName");
    localStorage.removeItem("token");
    localStorage.removeItem("tokenExpirationTime");
    localStorage.removeItem("authLocations");
    localStorage.removeItem("doorCountingEnabled");
    localStorage.removeItem("dashboardEnabled");
    localStorage.removeItem("userPreferences");
  };

  const loggedIn = !!email;

  const contextValue = {
    email: email,
    firstName: firstName,
    token: token,
    tokenExpirationTime: tokenExpirationTime,
    authLocations: authLocations,
    dashboardEnabled: dashboardEnabled,
    doorCountingEnabled: doorCountingEnabled,
    userPreferences: userPreferences,
    login: login,
    logout: logout,
    updateUserPreferences: updateUserPreferences,
    loggedIn: loggedIn,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
