import moment from "moment";
import Icon from "../atoms/Icon";
import Map from "../atoms/Map";
import { useContext, useEffect, useState } from "react";
import Button from "../atoms/Button";
import { MapContext } from "../../pages/Location";
import { getQueuesAPI } from "../../util";
import AuthContext from "../../contexts/auth-context";
import { Collapse } from "react-bootstrap";

export default function ZoneItem({
  x,
  data,
  currentSeries,
  currentGame,
  dayType,
  className,
}) {
  const authCtx = useContext(AuthContext);
  const [showMap, setShowMap] = useState(false);
  const [showAllMaps] = useContext(MapContext);
  const [, setShowEdit] = useState(false);
  const [, setEditing] = useState(false);
  const [label, setLabel] = useState("");

  const onChangeLabel = (e) => {
    let newLabel = e.target.value;
    setLabel(newLabel);
  };

  const handleBlur = (e) => {
    onBlurLabel(e);
    setShowEdit(false);
    setEditing(false);
  };

  const onBlurLabel = (e) => {
    // Remove whitespaces
    let newLabel = e.target.value.trim();
    if (newLabel === "") {
      newLabel = "Merch";
      setLabel(newLabel);
      saveUserPreference(newLabel, x.zoneId);
    } else {
      // Make sure to save the new label
      saveUserPreference(newLabel, x.zoneId);
    }
  };

  useEffect(() => {
    setLabel(findLabelByZoneId(x.zoneId));
  }, []);

  useEffect(() => {
    setShowMap(showAllMaps);
  }, [showAllMaps]);

  // Finds the user preference zone label given a zone id
  const findLabelByZoneId = (zoneId) => {
    const preference = authCtx.userPreferences.find(
      (pref) => pref.zoneId === zoneId
    );
    return preference ? preference.label : "Merch"; // Default to Merch if label not found
  };

  // Saves the zones new label both to local storage and backend
  const saveUserPreference = (newLabel, zId) => {
    const updatedPreferences = authCtx.userPreferences.map((preference) => {
      if (preference.zoneId === zId) {
        return { ...preference, label: newLabel };
      }
      return preference;
    });
    authCtx.updateUserPreferences(updatedPreferences);

    const bodyData = {
      preferences: {
        label: newLabel,
        zoneId: zId,
      },
    };
    getQueuesAPI(
      "update-clubhouse-user-preferences",
      bodyData,
      (data, error) => {
        if (error) {
          alert("Error updating user preferences:");
        }
      },
      authCtx.token
    );
  };

  return (
    <>
      <div className="col-4 p-3">
        <div
          className="shadow p-3 text-center panel-2"
          style={{
            // backgroundColor: "#fafefe",
            borderRadius: "18px",
          }}
          onMouseEnter={() => setShowEdit(true)}
          onMouseLeave={() => setShowEdit(false)}>
          <div className="h4">
            <div>Zone #{x.zoneId}</div>
            <div className="d-flex" style={{ marginTop: "8px" }}>
              <div className="input-group mx-auto">
                <input
                  className="form-control text-center"
                  style={{
                    // backgroundColor: "#fafefe",
                    fontSize: "20px",
                  }}
                  value={label}
                  onChange={onChangeLabel}
                  onBlur={handleBlur}
                  maxLength={25}
                />
                {/* <Button
                    onClick={() => setEditing(!editing)}
                    icon="pencil"
                    size="sm"
                    className="border-0"
                    visible={showEdit}
                  /> */}
              </div>
            </div>
          </div>
          <div className="my-3">
            <Button
              size="sm"
              icon="geo-fill"
              onClick={() => setShowMap(!showMap)}
              text={(showMap || showAllMaps ? "Hide" : "Show") + " Map"}
            />
          </div>

          <Collapse in={showMap}>
            <div>
              <Map
                className=""
                imageBg={require("../../vendors/braves/braves_clubhouse_store_sectioned_transparent.png")}
                viewBoxWidth={775}
                viewBoxHeight={1080}>
                <foreignObject
                  className={"bg-danger rounded-circle"}
                  width={70}
                  height={70}
                  x={x.redDotX}
                  y={x.redDotY}></foreignObject>
              </Map>
            </div>
          </Collapse>
          <hr />
          {x.peak.traffic !== -Infinity ? (
            <div className="h5">
              <div className="mb-3">
                <Icon name="person-standing" className="me-2" />
                Current Occupancy: {data}
              </div>
              {x.peak && x.peak.traffic >= 0 && (
                <div>
                  <Icon name="caret-up-fill" className="text-danger me-2" />
                  Peak:{" "}
                  {`${x.peak.traffic} (at ${moment(x.peak.time2).format(
                    "LT"
                  )})`}
                </div>
              )}
              {/* {JSON.stringify(currentGame.startTime)} */}
            </div>
          ) : (
            <div className="m-auto opacity-75">
              <Icon name="exclamation-diamond" className="me-2" />
              No Data Available.
            </div>
          )}
        </div>
      </div>
    </>
  );
}
