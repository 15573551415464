import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import "chartjs-adapter-moment";
import zoomPlugin from "chartjs-plugin-zoom";
import { Chart as ChartJS } from "chart.js";

ChartJS.register(zoomPlugin);

const colors = [
    '#0000FF', '#FF0000', '#008000', '#FFA500', 
  ];

const getColorForIndex = (index) => {
  return index < colors.length ? colors[index] : "#000000";
};


const OverviewTrafficChart = ({ trafficDataSets, dataSetNames, dataSetDates, isCounterView }) => {
  const [dataValues, setDataValues] = useState([]);
  const [loading, setLoading] = useState(true);
  const chartRef = useRef(null);

  useEffect(() => {
    setLoading(true);
  
    const processedTrafficData = [];
  
    // Process traffic data using forEach
    trafficDataSets.forEach((dataSet) => {
      const processedDataSet = [];
  
      dataSet.forEach((item) => {
        if (item.timestamp !== "00:00") {
          // We need to convert all times to today so we can overlay it on the graph (just the day)
          const inputTime = item.timestamp.split('T')[1];
          // Get today's date
          const today = new Date();
          const todayDate = today.toISOString().split('T')[0]; 
          // Combine today's date with the input time
          const combinedDateTimeStr = `${todayDate}T${inputTime}`;
          const entryDate = new Date(combinedDateTimeStr);
          const isoDateStr = entryDate.toISOString();
      
          processedDataSet.push({
            x: isoDateStr, // store iso datestr
            y: item.clear ? 0 : item.count, // Use 0 for 'clear' entries, else use the count
            clear: item.clear,
          });
        }
      });
  
      processedTrafficData.push(processedDataSet);
    });
  
    setDataValues(processedTrafficData);
    setLoading(false);
  }, [trafficDataSets]);
  
  
  const maxValue = Math.max(
    ...dataValues.flatMap((dataSet) => dataSet.map((item) => item.y))
  );

  const chartData = {
    labels: dataValues.length > 0 ? dataValues[0].map((item) => item.x) : [],
    datasets: dataValues.map((dataSet, index) => ({
      label: (dataSetNames[index] && !isCounterView) ? `${dataSetNames[index]} · ${dataSetDates[index]}` : '',
      data: dataSet.map((item) => ({ x: item.x, y: item.clear ? 0 : item.y })), // Plot 0 for 'clear' entries
      borderColor: isCounterView ? "#02A693" : getColorForIndex(index),
      backgroundColor: isCounterView ? "#02A693" : getColorForIndex(index),
      fill: false,
      pointRadius: 1,
      borderWidth: 2,
      tension: 0.4,
    })),
  };

  // Gets primary text color for chart
  const primaryTextColor = getComputedStyle(document.documentElement).getPropertyValue('--primary-txt').trim();

  const chartOptions = {
    scales: {
      x: {
        title: {
            color: primaryTextColor,
            display: true,
            text: "Time",
            padding: { top: 0, bottom: 10 },
        },
        type: "time",
        time: {
            unit: "hour",
            displayFormats: {
              hour: "h A",
            },
          },
        grid: {
          display: false,
        },
        ticks: {
          source: "auto",
          color: primaryTextColor,
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        display: true,
        title: {
          color: primaryTextColor,
          display: true,
          text: "People",
          padding: { top: 0, bottom: 10 },
        },
        grid: {
          display: true,
          
        },
        ticks: {
            color: primaryTextColor,
            precision: 0,
            beginAtZero: true,
            stepSize: isCounterView ? ((maxValue < 100) ? 20 : 100) : 200,
            callback: function (value) {
              if (isCounterView) {
                if (value % 100 === 0) {
                  return value;
                }
              } else {
                if (value % 200 === 0) {
                  return value;
                }
              }
            },
          },
          max: isCounterView
          ? ((maxValue < 100) ? 100 : Math.max(Math.ceil(maxValue / 100) * 100) + 100)
          : Math.max(Math.ceil(maxValue / 200) * 200) + 200
          },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        enabled: true,
        // leave this in case we want to have tooltip for this chart
        callbacks: {
            title: (context) => {
                if (context.length == 0) {
                  return "";
                } else {
                  const label = context[0].dataset.label;
                  const labelParts = context[0].label.split(',');  // split the time
                  const pulledTime = labelParts.length > 2 ? labelParts[2].trim() : '';  // grab the time not date
                  
                  return isCounterView ? `${pulledTime}` : `${label}\n${pulledTime}`;
                }
            },
            label: (context) => ` ${context.raw.y}`,
        },
      },
      legend: {
        display: isCounterView ? false : true,
        labels: {
          color: primaryTextColor,
          usePointStyle: true,
          pointStyle: 'rectRounded',
          filter: (legendItem, data) => {
            const index = legendItem.datasetIndex;
            return data.datasets[index] && data.datasets[index].data.length > 0;
          },
        },
      },
      zoom: {
        limits: {
          x: { min: 'original', max: 'original', minRange: 60 * 60 * 1000 },
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'x',
        },
        pan: {
          enabled: true,
          mode: 'x',
        },
      },
    },
  };

  if (!loading) {
    return (
      <div
      style={{
        paddingRight: "24px",
        paddingLeft: "24px",
        paddingTop: isCounterView ? "" : "8px",
        paddingBottom: isCounterView ? "" :"8px",
        borderRadius: isCounterView ? "" :"36px",
        marginTop: isCounterView ? "" : "16px",
        marginBottom: isCounterView ? "" : "16px",
        justifyContent: isCounterView ? "" : "center",
        // backgroundColor: "#fafefe",
        boxShadow: isCounterView ? "" :"0px 4px 8px rgba(0, 0, 0, 0.1)",
        position: isCounterView ? "" : "relative",
      }} className="graph panel-2">
        <div style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: "16px",
          marginRight: "16px"
        }}>
          <p style={{ fontSize: "24px" }}>
            Traffic Count
          </p>
          <div>
            <button
              style={{ marginRight: "5px" }}
              className={"btn border-1"}
              onClick={() => chartRef.current?.zoom(1.25)}
            >
              +
            </button>
            <button
              style={{ marginRight: "5px" }}
              className={"btn border-1"}
              onClick={() => chartRef.current?.zoom(0.75)}
            >
              -
            </button>
            <button
              className={"btn border-1"}
              onClick={() => chartRef.current?.resetZoom()}
            >
              Reset
            </button>
          </div>
        </div>
        <div style={{ position: 'relative', height: '300px', marginTop: '8px' }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflowX: 'auto',
          }}>
            <div style={{ width: '100%', height: '100%' }}>
              <Line ref={chartRef} data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <p>Loading...</p>;
};

export default OverviewTrafficChart;
