import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { getQueuesAPI } from "../../util";
import moment from "moment";
import AuthContext from "../../contexts/auth-context";
import OverviewTrafficChart from "../atoms/OverviewTrafficChart";
import LegacyGameCounts from "../../vendors/braves/legacyGameCounts.json";

export default function Counter({ isToday, selectedDate, currentGame }) {
  const authCtx = useContext(AuthContext);

  const [overviewSelectedDates, setOverviewSelectedDates] = useState([]);
  const [overviewTrafficDataList, setOverviewTrafficDataList] = useState([]);
  const [overviewGameTitlesList, setOverviewGameTitlesList] = useState([]);

  const [pregameCount, setPregameCount] = useState(0);
  const [gatesOpenCount, setGatesOpenCount] = useState(0);
  const [firstPitchCount, setFirstPitchCount] = useState(0);

  const [hasError, setHasError] = useState(false);

  const intervalRef = useRef(null);

  // Error handling helper function
  const handleError = useCallback((message) => {
    alert(message);
    setHasError(true);
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  // Get counts
  const processCounterData = useCallback(
    (data) => {
      const { doorsOpen, gameStartTime } = currentGame;

      // temp vars to not mess with states
      let tempPregameCount = pregameCount;
      let tempGatesOpenCount = gatesOpenCount;
      let tempFirstPitchCount = firstPitchCount;

      data.forEach((entry) => {
        if (entry.clear) return; // Skip if clear is true - 1st entry is always a clear

        const entryTime = entry.timestamp.split("T")[1].slice(0, 5);

        // If entry is before gates open then it is our pregame count
        // No count will be recorded if there are no entries before Gates Open
        if (entryTime < doorsOpen) {
          tempPregameCount = entry.count;
        }

        // After Gates Open Time and before First Pitch Time
        else if (entryTime >= doorsOpen && entryTime < gameStartTime) {
          tempGatesOpenCount = Math.abs(tempPregameCount - entry.count);
        }

        // After first pitch time
        else if (entryTime >= gameStartTime) {
          tempFirstPitchCount = Math.abs(
            tempPregameCount + tempGatesOpenCount - entry.count
          );
        }
      });

      setPregameCount(tempPregameCount);
      setGatesOpenCount(tempGatesOpenCount);
      setFirstPitchCount(tempFirstPitchCount);
    },
    [currentGame, pregameCount, gatesOpenCount, firstPitchCount]
  );

  // Fetch data function
  const fetchData = useCallback(() => {
    getQueuesAPI(
      "get-clubhouse-daily-door-count",
      {
        locationId: 1,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      },
      (data) => {
        if (data) {
          // We are using hard coded count data for games that happened before 2024-10-01
          // Otherwise process data normally
          const selectedDateFormatted =
            moment(selectedDate).format("YYYY-MM-DD");
          if (selectedDateFormatted < "2024-10-01") {
            // Find the corresponding data from the JSON file based on the date
            const gameData = LegacyGameCounts.find(
              (game) => game.date === selectedDateFormatted
            );
            setPregameCount(gameData?.pregameCount ?? 0);
            setGatesOpenCount(gameData?.gatesOpenCount ?? 0);
            setFirstPitchCount(gameData?.firstPitchCount ?? 0);
          } else {
            // Standard way we process the data for the counter views (unrelated to the charts)
            processCounterData(data);
          }
          setHasError(false);

          // Set data for the chart
          setOverviewTrafficDataList([data]);
          setOverviewSelectedDates([selectedDate]);
          setOverviewGameTitlesList(["Traffic Data"]);
        } else {
          handleError("Failed to fetch daily door count.");
        }
      },
      authCtx.token
    );
  }, [authCtx.token, selectedDate, handleError, processCounterData]);

  // Format timestamp
  function convertTo12Hour(time) {
    // TODO this will change if backend still has pregameTime entry or if it is ommited if no pregame
    // Ex. pregame: 11:25 vs ----- (doesn't exist)
    if (!time) {
      // We can hard code pregame here because the only time that should ever sometimes not exist is pregame
      return " ";
    }

    let [hours, minutes] = time.split(":");

    hours = parseInt(hours, 10);

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12;

    // Return formatted time with leading zeros for minutes if needed
    return `${hours}:${minutes} ${ampm}`;
  }

  // Fetch data on selectedDate change
  useEffect(() => {
    fetchData();
  }, [selectedDate, fetchData]);

  // Auto-reload data (only if live day)
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    if (isToday && !hasError) {
      intervalRef.current = setInterval(() => {
        fetchData();
      }, 30000);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isToday, hasError, fetchData]);

  const styles = {
    container: {
      borderRadius: "20px",
      // backgroundColor: "#fafefe",
      maxWidth: "100%",
      height: "320px",
      margin: "30px auto 20px",
      display: "flex",
      flexWrap: "nowrap", // Prevent items from wrapping
    },
    container2: {
      borderRadius: "20px",
      // backgroundColor: "#fafefe",
      maxWidth: "100%",
      height: "800px",
      margin: "30px auto 20px",
    },
    leftSide: {
      flex: 8,
      display: "flex",
      flexDirection: "row-reverse",
      overflowX: "auto",
      alignItems: "center",
      gap: "clamp(20px, 5vw, 60px)",
      borderRight: "1px solid #ccc",
      justifyContent: "safe center", // Ensures scrolling shows all content
    },
    title: {
      textAlign: "center",
      marginBottom: "10px",
      fontSize: "clamp(14px, 2.5vw, 24px)",
      fontWeight: "bold",
    },
    counterDisplay: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      // color: "#002B5C",
      fontSize: "clamp(16px, 4vw, 60px)",
      padding: "10px", // Ensure padding is consistent
      borderRadius: "12px",
      border: "6px solid #002B5C",
      width: "200px", // Let it take the full width of the wrapper
      boxSizing: "border-box", // Make sure padding is inside the width
      textAlign: "center",
    },
    counterWrapper: {
      // backgroundColor: "#f0f0f0", // Light grey background
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.4)", // Light shadow
      borderRadius: "15px", // Slightly rounded corners
      padding: "20px", // Padding around content
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "300px", // Set a consistent width
      boxSizing: "border-box", // Ensure padding is accounted for
    },
    rightSide: {
      flex: 2,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "20px",
      paddingRight: "20px",
    },
    logTitle: {
      textAlign: "center",
      marginBottom: "10px",
      fontSize: "36px",
      fontWeight: "bold",
    },
    logContainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "center",
      overflowY: "auto",
      width: "100%",
      maxHeight: "calc(90% - 60px)",
      border: "6px solid #002B5C",
      borderRadius: "10px",
      padding: "0px",
      boxSizing: "border-box",
    },
    logEntry: {
      marginBottom: "5px",
      textAlign: "center",
      fontSize: "18px",
    },
  };

  return (
    <div className="p-3 shadow panel-2" style={styles.container2}>
      <div style={styles.container}>
        {/* Left Side */}
        <div style={styles.leftSide}>
          {/* {trafficData.map((item, index) => {
            const [[time, count]] = Object.entries(item);
            const title = counterTitles[index] || "Event"; // Assign title for first three items
  
            return (
              <div key={index} style={styles.counterWrapper}>
                <div style={styles.title}>{title}</div>
                <div style={styles.counterDisplay}>
                  {count.toString()}
                </div>
                <div style={styles.title}>{time}</div>
              </div>
            );
          })} */}
          <div className="elevated-card-2" style={styles.counterWrapper}>
            <div style={styles.title}>First Pitch</div>
            <div style={styles.counterDisplay}>
              {firstPitchCount.toLocaleString()}
            </div>
            <div style={styles.title}>
              {convertTo12Hour(currentGame.gameStartTime)}
            </div>
          </div>

          <div className="elevated-card-2" style={styles.counterWrapper}>
            <div style={styles.title}>Gates Open</div>
            <div style={styles.counterDisplay}>
              {gatesOpenCount.toLocaleString()}
            </div>
            <div style={styles.title}>
              {convertTo12Hour(currentGame.doorsOpen)}
            </div>
          </div>
          {/* We only show pregame counter if we have a pregame count not 0 */}
          {pregameCount > 0 && (
            <div className="elevated-card-2" style={styles.counterWrapper}>
              <div style={styles.title}>Pregame</div>
              <div style={styles.counterDisplay}>
                {pregameCount.toLocaleString()}
              </div>
              <div style={styles.title}>
                {convertTo12Hour(currentGame.pregameTime)}
              </div>
            </div>
          )}
        </div>

        {/* Right Side */}
        <div style={styles.rightSide}>
          <div style={{ fontSize: "42px", fontWeight: "bold" }}>
            {(gatesOpenCount + firstPitchCount).toLocaleString()}
          </div>
          <div style={{ fontSize: "24px" }}>Game Traffic</div>
          <div
            style={{ marginTop: "40px", fontSize: "42px", fontWeight: "bold" }}>
            {(pregameCount + gatesOpenCount + firstPitchCount).toLocaleString()}
          </div>
          <div style={{ fontSize: "24px" }}>Day Traffic</div>
        </div>
      </div>
      <hr style={{ marginBottom: "16px" }}></hr>
      <div>
        <OverviewTrafficChart
          trafficDataSets={overviewTrafficDataList}
          dataSetNames={overviewGameTitlesList}
          dataSetDates={overviewSelectedDates}
          isCounterView={true}
        />
      </div>
    </div>
  );
}
