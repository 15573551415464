import { useContext, useEffect, useState } from "react";
import AuthContext from "../contexts/auth-context";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo_1x.png";
import Button from "../components/atoms/Button";
import { getQueuesAPI } from "../util";
import Input from "../components/atoms/Input";

export default function Auth() {
  const authCtx = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    authCtx.logout();
  }, [navigate]);

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const login = (e) => {
    e.preventDefault();
    getQueuesAPI(
      "loginFrontEnd",
      {
        email: email,
        pwd: password,
        venue: "braves",
      },
      (data, error) => {
        if (error) {
          setError("Invalid username or password.");
          setTimeout(() => setError(""), 2000);
        } else if (data && data.success) {
          setError("");
          if (data.dashboardEnabled) {
            authCtx.login(
              email,
              data.name,
              data.sessionToken,
              data.expiration_time,
              data.authLocations,
              data.doorCountingEnabled,
              data.dashboardEnabled,
              data.userPreferences
            );
            navigate("/locations");
          } else {
            setError("You do not have access to this page.");
            setTimeout(() => setError(""), 2000);
          }
        } else {
          setError("Invalid username or password.");
          setTimeout(() => setError(""), 2000);
        }
      }
    );
  };

  return (
    <div className="centered-container">
      <img alt="" width="225" src={logo} />
      <form onSubmit={login} className="p-3" style={{width: '100%', maxWidth: '400px', minWidth: '200px'}}>
        <Input
          required={true}
          className="mb-3 textfield-auth"
          placeholder="E-mail"
          value={email}
          onChange={onChangeEmail}
        />
        <Input
          required={true}
          className="mb-3 textfield-auth"
          placeholder="Password"
          type="password"
          value={password}
          onChange={onChangePassword}
        />
        <Button
          className="w-100"
          type="submit"
          disabled={email.length == 0 || password.length == 0}
          style={{
            backgroundColor: email.length == 0 || password.length == 0 ? "#cccccc" : "#02a693",
            cursor: email.length == 0 || password.length == 0 ? "not-allowed" : "pointer",
            color: email.length == 0 || password.length == 0 ? "#666666" : "white",
            border: "none",
            opacity: email.length == 0 || password.length == 0 ? 0.6 : 1,
          }}
          icon={error !== "" ? "exclamation-circle-fill" : ""}
          text={error === "" ? "Log In" : error}
        />
      </form>
      <Link to="forgotPassword">
        <Button text="Forgot Password?" className="border-0" />
      </Link>
    </div>
  );
}
