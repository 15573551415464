import React from "react";

const css = `
.cls-1 {
  fill: #b1b4b3;
}
.cls-2 {
  fill: #e8edeb;
}
.cls-3 {
  fill: #939997;
}
/* Hover effect for zones */
#zone-1:hover, #zone-2:hover, #zone-3:hover, #zone-4:hover, #zone-5:hover,
#zone-6:hover, #zone-7:hover, #zone-8:hover, #zone-9:hover, #zone-10:hover,
#zone-11:hover, #zone-12:hover, #zone-13:hover, #zone-14:hover, #zone-15:hover
, #zone-16:hover, #zone-17:hover, #zone-18:hover {
fill: #02a693;
opacity: 1;
cursor: pointer;
}
`;

const BravesClubhouseSvg = ({ children }) => {
  return (
    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 793.12 962.84">
      <defs>
        <style>{css}</style>
    </defs>
    <path className="cls-2" d="M603.3,18.4l140.6,124.9-84.9,102.9,1,176.7,35.3,35.8,24-15.8,70.8,77.3v125.1h-221v162.5h-45.7v25.7h-75.5v-23.6h-208v128.9h-38v23.1h-46.1v-23.7c.1,0-8.9.1-8.9.1v23.5h-46v-24.2h-34.8v-18H13v-288l208.6-242.4-11.2-8.9,42.8-48.7,10,8.6,54.3-62.4,34.2,29.9L603.3,18.4"/>
    <path className="cls-3" d="M603.3,18.4l140.6,124.9-84.9,102.9,1,176.7,35.3,35.8,24-15.8,70.8,77.3v125.1h-221v162.5h-45.7v25.7h-75.5v-23.6h-208v128.9h-38v23.1h-46.1v-23.7c.1,0-8.9.1-8.9.1v23.5h-46v-24.2h-34.8v-18H13v-288l208.6-242.4-11.2-8.9,42.8-48.7,10,8.6,54.3-62.4,34.2,29.9L603.3,18.4M602.6,7l-5.3,6.1-246.3,283.2-28.2-24.6-6-5.3-5.3,6-49.1,56.5-4-3.4-6-5.1-5.2,5.9-42.8,48.7-5.6,6.3,6.6,5.2,4.7,3.7L6.9,626.4l-1.9,2.2v298.8h8s45.1.1,45.1.1v18.1h34.8v24.2h117v-23.1h38v-128.9h192v23.6h91.5v-25.7h45.7v-162.5h221v-136.2l-2.1-2.3-70.8-77.3-4.6-5-5.7,3.8-18.5,12.2-28.4-28.8-.9-170.6,83.1-100.7,4.9-5.9-5.8-5.1L608.6,12.4l-6-5.4h0Z"/>
      <rect x="99.3" y="956.2" width="49" height="6.2"/>
      <rect x="154.3" y="956.2" width="49" height="6.2"/>
      <polygon points="230.1 355.6 230.1 355.6 208.4 380.5 213.1 384.6 234.8 359.7 234.8 359.7 257.6 333.5 253 329.4 230.1 355.6"/>
      <polygon points="700.3 104.7 674.4 81.5 674.4 81.5 649.7 59.5 645.6 64.1 671.5 87.2 671.5 87.2 696.2 109.3 700.3 104.7"/>
    {children}
    </svg>
  );
};

export default BravesClubhouseSvg;
