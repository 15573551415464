import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { Alert } from 'react-bootstrap';

const OutageAlert = ({ show, onDismiss }) => {
  return (
    <>
      <CSSTransition
        in={show}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div className="alert-container">
          <Alert variant="warning" onClose={onDismiss} dismissible className="py-2 px-3 mb-0">
            <Alert.Heading className="h6 mb-1">An outage has been detected.</Alert.Heading>
            <p className="small mb-0">We are actively working on resolving this!</p>
          </Alert>
        </div>
      </CSSTransition>

      <style>{`
        .alert-container {
          position: fixed;
          bottom: 1rem;
          right: 0;
          left: 75px; 
          width: auto;
          padding: 0 1rem;
          z-index: 1050;
        }
        .alert-enter {
          opacity: 0;
          transform: translateY(1rem);
        }
        .alert-enter-active {
          opacity: 1;
          transform: translateY(0);
          transition: opacity 300ms, transform 300ms;
        }
        .alert-exit {
          opacity: 1;
          transform: translateY(0);
        }
        .alert-exit-active {
          opacity: 0;
          transform: translateY(1rem);
          transition: opacity 300ms, transform 300ms;
        }
      `}</style>
    </>
  );
};

export default OutageAlert;
