import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../contexts/auth-context";
import { FaEnvelope, FaPlus } from "react-icons/fa";
import Icon from "../components/atoms/Icon";
import { ThemeContext } from "../components/molecules/Nav";
import { getTeam } from "../util.js";

const Account = () => {
    const authCtx = useContext(AuthContext);
    const navigate = useNavigate();
    const [theme, setTheme] = useContext(ThemeContext);

    const logout = () => {
        authCtx.logout();
        navigate("/auth");
    };

    const forgotPassword = () => {
        navigate("/auth/forgotPassword");
    };

    const accountData = {
        name: authCtx.firstName,
        email: authCtx.email,
        doorCountingEnabled: authCtx.doorCountingEnabled,
    };

    const containerStyle = {
        width: "100%",
        height: "100%",
        padding: "30px",
        paddingTop: "50px",
        boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.1)",
        fontFamily: "Arial, sans-serif",
        textAlign: "left",
        marginLeft: "0",
    };

    const profileSectionStyle = {
        display: "flex",
        flexDirection: "row", // Align items in a row
        alignItems: "center", // Center vertically
        marginBottom: "10px",
    };

    const profileImageStyle = {
        width: "80px",
        height: "80px",
        overflow: "hidden", // Ensures the image stays within the circle
        marginRight: "16px", // Adds spacing between the image and name
    };

    const headingStyle = {
        fontSize: "24px",
        fontWeight: "600",
        marginBottom: "0",
    };

    const infoRowStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        marginBottom: "12px",
        fontSize: "16px",
    };

    const subheadlineStyle = {
        fontSize: "18px",
        fontWeight: "500",
        marginTop: "10px",
        marginBottom: "12px",
    };

    const iconStyle = {
        marginRight: "10px",
        color: "#53b8aa",
    };

    const buttonContainerStyle = {
        display: "flex",
        justifyContent: "space-between",
        width: "25%",
        marginTop: "40px",
    };

    const buttonStyle = {
        width: "48%",
        padding: "10px",
        backgroundColor: "#53b8aa",
        color: "#fff",
        border: "none",
        borderRadius: "8px",
        cursor: "pointer",
        fontSize: "16px",
        fontWeight: "400",
        transition: "background-color 0.3s",
        textAlign: "center",
    };

    const handleLogoutHover = (event) => {
        event.target.style.backgroundColor = "#45a69d";
    };

    const handleLogoutLeave = (event) => {
        event.target.style.backgroundColor = "#53b8aa";
    };

    return (
        <div className="p-3 shadow-lg d-block panel-2" style={containerStyle}>
            <div style={profileSectionStyle}>
                <div style={profileImageStyle}>
                    <img
                        src={getTeam(0).imgURL} // hardcoded braves logo 
                        alt="Team"
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        }}
                    />
                </div>
                <h2 style={headingStyle}>{accountData.name}</h2>
            </div>
            <hr />
            <div style={infoRowStyle}>
                <FaEnvelope style={iconStyle} />
                <span>{accountData.email}</span>
            </div>

            <h3 style={subheadlineStyle}>Account Permissions</h3>

            <div style={infoRowStyle}>
                <FaPlus style={iconStyle} />
                <span>
                    {accountData.doorCountingEnabled
                        ? "Traffic Counter Enabled"
                        : "Traffic Counter Disabled"}
                </span>
            </div>

            <h3 style={subheadlineStyle}>Settings</h3>

            <div className="">
                <button
                    onClick={() => setTheme(theme === "light" ? "dark" : "light")}
                    className="nav-item-custom btn w-10 text-capitalize"
                >
                    <Icon name={(theme === "light" ? "sun" : "moon") + "-fill"} />
                    <span style={{ marginLeft: "8px" }}>{theme} mode</span>
                </button>
            </div>

            <div style={buttonContainerStyle}>
                <button
                    style={buttonStyle}
                    onClick={logout}
                    onMouseEnter={handleLogoutHover}
                    onMouseLeave={handleLogoutLeave}
                >
                    Logout
                </button>

                <button
                    style={buttonStyle}
                    onClick={forgotPassword}
                    onMouseEnter={handleLogoutHover}
                    onMouseLeave={handleLogoutLeave}
                >
                    Reset Password
                </button>
            </div>
        </div>
    );
};

export default Account;
