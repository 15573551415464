import React, { Fragment } from 'react';
import { getDates } from './helper_functions.js';
import { getTeam } from '../../util.js';
import { customMoment as moment } from '../../util.js';
import Icon from '../../components/atoms/Icon.js';
import Button from '../../components/atoms/Button.js';
import ButtonGroup from '../../components/molecules/ButtonGroup.js';
import { v4 as uuidv4 } from 'uuid';

const isGameSelected = (game, series, currentSeries, currentGame) => {
  return series?.teamID === currentSeries?.teamID && game.date === currentGame?.date;
};

const GameSelector = ({
  loading,
  currentSeries,
  currentGame,
  currentSeason,
  setCurrentSeason,
  games,
  now,
  setCurrentGame,
  setCurrentSeries,
  setSelectedDate,
  theme
}) => {
  return (
    <ButtonGroup className="between w-100 mt-3 panel-2">
      <div className={`btn-group dropdown w-${currentSeries ? "50" : "100"}`}>
        <button
          data-bs-auto-close={true}
          data-bs-target="#games"
          data-bs-toggle="dropdown"
          className={`btn between${loading ? " disabled" : ""}`}>
          {currentSeries ? (
            <span>
              <img
                alt="Opponent"
                className="me-1"
                width={20}
                height={20}
                src={getTeam(currentSeries.teamID)?.imgURL}
              />
              <span>{getTeam(currentSeries.teamID)?.name}</span>
              {" @ "}
              <img
                alt="Braves"
                className="me-1"
                width={20}
                height={20}
                src={require("../../teams/atl-braves-a_40_px.png")}
              />
              <span className="me-1">Braves</span>
              {`(${moment(getDates(currentSeries.dates)[0]).format("MMM D")}`}
              {" - "}
              {`${moment(getDates(currentSeries.dates)[1]).format("MMM D")})`}
            </span>
          ) : (
            <span>Select A Game</span>
          )}
          <Icon name="caret-down-fill" className="ms-2" />
        </button>

        <div className={`dropdown-menu w-100 panel-2 border ${theme === "light" ? "border-black" : "border-white"}`} id="games">
          <ButtonGroup size="sm" className="my-1">
            <Button text="Season:" className="non-btn" />
            {["2024", "2025"].map((season) => (
              <Button
                key={season}
                onClick={() => setCurrentSeason(season)}
                text={season}
                className={
                  (currentSeason === season ? " active" : "") +
                  (moment(now).year() < parseInt(season)
                    ? " disabled"
                    : "")
                }

              />
            ))}
          </ButtonGroup>

          {games.map((series) => (
            <Fragment key={uuidv4()}>
              <div
                className={`px-3 py-2 between${now < moment(series.dates[0].date) ? " opacity-25" : ""
                  }`}>
                <div className="d-flex">
                  <div className="me-1 fw-bold">
                    <img
                      alt="Opponent"
                      className="me-2"
                      width={20}
                      height={20}
                      src={getTeam(series.teamID).imgURL}
                    />
                    <span>{getTeam(series.teamID)?.name}</span>
                  </div>
                  <span>
                    <span>
                      ({moment(getDates(series.dates)[0]).format("MMM D")}
                    </span>
                    {" - "}
                    <span>
                      {moment(getDates(series.dates)[1]).format("MMM D")})
                    </span>
                  </span>
                </div>
                <div className="d-flex">
                  {series.dates.map((game, idx) => (
                    <Fragment key={uuidv4()}>
                      {now > moment(game.date) && (
                        <button
                          onClick={() => {
                            setCurrentGame(game);
                            setCurrentSeries(series);
                            setSelectedDate(moment(game.date).toDate());
                          }}
                          className={`btn mx-1 py-0${isGameSelected(game, series, currentSeries, currentGame) ? " active" : ""
                        }${game.date === "2024-09-25" ? " postponed" : ""}`}>
                          {idx + 1}
                          {game.date === "2024-09-25" && (
                            <Icon name="exclamation-circle-fill ms-2" />
                          )}
                        </button>
                      )}
                    </Fragment>
                  ))}
                </div>
              </div>
            </Fragment>
          ))}
          <div className="small postponed-text">
            <Icon name="exclamation-circle-fill" />
            <span className="ms-1"> - Game was postponed</span>
          </div>
        </div>
      </div>

      {currentSeries && (
        <ButtonGroup className="w-50">
          {currentSeries.dates.map((game, idx) => (
            <Fragment key={uuidv4()}>
              {now > moment(game.date) && (
                <Button
                  onClick={() => {
                    setCurrentGame(game);
                    setSelectedDate(moment(game.date).toDate());
                  }}
                  className={`${currentGame === game ? "active" : ""}${loading ? " disabled" : ""}`}
                  text={`Game ${idx + 1} (${moment(currentSeries.dates[idx].date).format("ddd, MMM D")})`}
                />
              )}
            </Fragment>
          ))}
        </ButtonGroup>
      )}
    </ButtonGroup>
  );
};

export default GameSelector;