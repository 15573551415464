import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-icons/font/bootstrap-icons.css";

import "./App.css";
import Nav from "./components/molecules/Nav";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Locations from "./pages/Locations";
import Location from "./pages/Location";
import Account from "./pages/Account";
import { AuthContextProvider } from "./contexts/auth-context";
import Auth from "./pages/Auth";
import ForgotPassword from "./pages/ForgotPassword";
import { useEffect } from "react";
import ReactGA from "react-ga";

// Initialize Google Analytics
ReactGA.initialize("G-RL7WP14SN0");

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
};

const PageTracker = () => {
  usePageTracking();
  return null;
};

function App() {
  return (
    <AuthContextProvider>
      <BrowserRouter>
        <PageTracker />
        <Routes>
          <Route path="/" element={<Nav />}>
            <Route path="/auth" element={<Auth />} />
            <Route path="/auth/forgotPassword" element={<ForgotPassword />} />
            <Route path="/locations" element={<Locations />} />
            <Route path="/locations/:name" element={<Location />} />
            <Route path="/account" element={<Account />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthContextProvider>
  );
}

export default App;
