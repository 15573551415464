import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo_1x.png";
import Button from "../components/atoms/Button";
import { getQueuesAPI } from "../util";
import Spinner from "../components/atoms/Spinner";
import ReactCodeInput from 'react-code-input';
import AuthContext from "../contexts/auth-context";
import { useContext } from "react";

export default function ForgotPassword() {
  const [stage, setStage] = useState(1);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [resetCode, setResetCode] = useState("");
  const [newPass, setNewPass] = useState("");
  const [newPassConfirm, setNewPassConfirm] = useState("");
  const [attempts, setAttempts] = useState(0);

  const onChangeEmail = (e) => setEmail(e.target.value);
  const onChangeResetCode = (value) => setResetCode(value);
  const onChangeNewPass = (e) => setNewPass(e.target.value);
  const onChangeNewPassConfirm = (e) => setNewPassConfirm(e.target.value);
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const requestCode = (e) => {
    e.preventDefault();
    setLoading(true);
    getQueuesAPI(
      "send-dashboard-password-reset-code",
      {
        email: email,
        venue: "braves"
      },
      (data, error) => {
        setLoading(false);
        if (error) {
          setError("Failed to send reset code. Please try again.");
        } else if (data === "Email Successfully Sent") {
          // At this point if the user is logged in - log them out
          if (authCtx.loggedIn) {
            authCtx.logout();
          }
          setStage(2);
          setError("");
        } else {
          setError("Email not found");
        }
        if (error) {
          alert(error);
        }
        setTimeout(() => setError(""), 1500);
      }
    );
  };

  const verifyCode = (e) => {
    e.preventDefault();
    setLoading(true);
    getQueuesAPI(
      "validate-dashboard-password-reset-code",
      {
        email: email,
        resetCode: resetCode,
        venue: "braves"
      },
      (data, error) => {
        setLoading(false);
        if (error) {
          console.error("Error validating reset code:", error);
          setError("Failed to validate reset code. Please try again.");
        } else if (data === "Reset Code Valid") {
          setStage(3);
          setError("");
        } else {
          setAttempts((prevAttempts) => {
            const newAttempts = prevAttempts + 1;
            const errorMessage =
              newAttempts < 2
                ? "Reset Code Invalid."
                : "Reset Code Expired. Try again to get another code";
            setError(errorMessage);
            return newAttempts;
          });
        }
      }
    );
  };

  useEffect(() => {
    if (attempts === 2) {
      setResetCode("");
      setStage(1);
    }
  }, [attempts]);

  useEffect(() => {
    if (stage === 1) {
      setAttempts(0);
    }
  }, [stage]);

  const changePassword = (e) => {
    e.preventDefault();
    setLoading(true);
    getQueuesAPI(
      "reset-password-dashboard",
      {
        email: email,
        resetCode: resetCode,
        newPass: newPass,
        newPassVerification: newPassConfirm,
        venue: "braves"
      },
      (data, error) => {
        setLoading(false);
        if (error) {
          const errorMessage = "Failed to reset password. Please try again.";
          setError(errorMessage);
        } else if (data === "Password Reset Successfully") {
          alert("Password Reset Successfully!");
          setError("");
          navigate("/auth");
        } else {
          const errorMessage = "Password change failed. Please make sure the new password is entered correctly twice.";
          setError(errorMessage);
        }
      }
    );
  };

  return (
    <div className="centered-container">
      <div className="m-4">
        {loading ? (
          <Spinner size={60} />
        ) : (
          <img alt="" width="225" src={logo} />
        )}
      </div>
      {stage === 1 ? (
        <>
          <p className="">
            Enter your email and we will send you a link to reset your password.
          </p>
          <form onSubmit={requestCode} className="p-0" style={{ maxWidth: '400px', minWidth: '250px', width: '100%' }}>
            <input
              required
              className="form-control mb-3 textfield-auth"
              autoComplete="off"
              placeholder="E-mail"
              value={email}
              onChange={onChangeEmail}
            />
            <Button 
              className="w-100" 
              type="submit" 
              text="Reset Password"
              style={{
                backgroundColor: email.length == 0 ? "#cccccc" : "#02a693", 
                cursor: email.length == 0 ? "not-allowed" : "pointer",
                color: email.length == 0 ? "#666666" : "white",
                border: "none",
                opacity: email.length == 0 ? 0.6 : 1, 
              }}
            />
          </form>
        </>
      ) : stage === 2 ? (
        <>
          <p className="">Enter the code you were sent.</p>
          <form onSubmit={verifyCode} className="p-0" style={{ maxWidth: '300px', minWidth: '250px', width: '100%' }}>
            <input
              readOnly
              required
              className="form-control mb-3 textfield-auth"
              autoComplete="off"
              value={email}
              disabled={true}
              onChange={onChangeEmail}
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <ReactCodeInput
                type="number"
                fields={6}
                onChange={onChangeResetCode}
                value={resetCode}
              />
            </div>
            <Button 
              className="w-100" 
              type="submit" 
              text="Verify Code"
              disabled={resetCode.length !== 6} // Correctly disable button
              style={{
                  backgroundColor: resetCode.length === 6 ? "#02a693" : "#cccccc", // Button color based on input length
                  cursor: resetCode.length === 6 ? "pointer" : "not-allowed",
                  color: resetCode.length === 6 ? "white" : "#666666",
                  border: "none", // Ensure the border style doesn’t interfere
                  opacity: resetCode.length === 6 ? 1 : 0.6, // Add opacity for visual feedback
              }}
            />
          </form>
        </>
      ) : (
        <>
          <p className="">Enter new password.</p>
          <form onSubmit={changePassword} className="p-0" style={{ maxWidth: '400px', minWidth: '250px', width: '100%' }}>
            <input
              type="password"
              required
              className="form-control mb-3 textfield-auth"
              autoComplete="off"
              placeholder="New Password"
              value={newPass}
              onChange={onChangeNewPass}
            />
            <input
              type="password"
              required
              className="form-control mb-3 textfield-auth"
              autoComplete="off"
              placeholder="New Password (Again)"
              value={newPassConfirm}
              onChange={onChangeNewPassConfirm}
            />
            <Button 
              className="w-100" 
              type="submit" 
              text="Change Password"
              style={{
                backgroundColor: newPass.length == 0 || newPassConfirm.length == 0 ? "#cccccc" : "#02a693",
                cursor: newPass.length == 0 || newPassConfirm.length == 0 ? "not-allowed" : "pointer",
                color: newPass.length == 0 || newPassConfirm.length == 0 ? "#666666" : "white",
                border: "none",
                opacity: newPass.length == 0 || newPassConfirm.length == 0 ? 0.6 : 1,
              }}
            />
          </form>
        </>
      )}
    </div>
  );
}
