// Time constants
export const DOORS_OPEN_TIME = 600; // 10:00 AM EST (used multiple times in the code)
export const GAME_END_CUTOFF = 1285;
export const DOORS_OPEN_BUFFER = 30; // Minutes before doors open for average calculation
export const GAME_END_BUFFER = 210; // 3.5 hours after game start for average calculation

// Sort options for zone display
export const SORT_OPTIONS = [
  { name: "Zone ID", code: "zone-id" },
  { name: "Current Occupancy", code: "current" },
  { name: "Peak Occupancy", code: "peak" }
];

// Day types
export const DAY_TYPES = {
  GAME: "game",
  REGULAR: "regular"
};

// Section types
export const SECTION_TYPES = {
  MAP: "Map",
  COUNTER: "Counter",
  CHARTS: "Charts",
  ZONES: "Zones",
  ALL: "all"
};

// Default values
export const DEFAULT_ZONE_LABEL = "Merch";
export const MAX_OCCUPANCY = 191; // Used in percentage calculations

// Date constants
export const CALENDAR_MIN_DATE = "2024-08-15";